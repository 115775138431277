import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ExpenseAllocationSplit from './ExpenseAllocationSplit';
import { Button, Table } from 'semantic-ui-react';
import { NullableCurrency } from '../../common/formatting';

class ExpenseAllocationSplits extends Component {
    constructor(props) {
        super(props);

        this.state = {
            splitOptions: [
                { key: 0, value: 0, text: '$' },
                { key: 1, value: 1, text: '%' }
            ],
            accountOptions: props.accounts.map((acct, i) => {
                return {
                    key: i,
                    value: acct.id,
                    text: acct.name
                };
            })
        };

        this.onSplitChange = this.onSplitChange.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.renderSplits = this.renderSplits.bind(this);
    }

    onSplitChange() {
        const { splits, onChange } = this.props;
        onChange(splits);
    }

    onAdd() {
        const { splits, onChange, invoiceAmount } = this.props;
        const { accountOptions } = this.state;

        const firstAccountValue = accountOptions[0].value;
        let pendingamount = 0;  
        let percentage = 0;        
        let total = 0;   
        let amountvalue = 0;
        let Conversionvalue = 0;

        let type = 0;
        splits.forEach((split) => {

            if (split.amount === "") {
                split.amount = 0;
            }
            total += split.type === 0 ? split.amount : (split.amount / 100) * invoiceAmount;          
            if (split.type === 1) {

                amountvalue = parseInt(split.amount, 10);
                percentage = parseInt(percentage, 10) + amountvalue;
            }           
            type = split.type;
        });


       
            //if (type === 0) {                
            //        pendingamount = invoiceAmount - total;
            //    splits.push({ expenseAllocationAccountId: firstAccountValue, amount: pendingamount, type: 0 });
            //}
            //else {  
            //    pendingamount = invoiceAmount - total;
            //    Conversionvalue = (pendingamount / invoiceAmount) * 100;              
            //    splits.push({ expenseAllocationAccountId: firstAccountValue, amount: Conversionvalue, type: 1 });
            //}


        if (type === 0) {
            pendingamount = invoiceAmount - total;
            splits.push({ expenseAllocationAccountId: firstAccountValue, amount: pendingamount, type: 0, UserselectedAmount: invoiceAmount });
        }
        else {
            pendingamount = invoiceAmount - total;
            Conversionvalue = (pendingamount / invoiceAmount) * 100;
            splits.push({ expenseAllocationAccountId: firstAccountValue, amount: Conversionvalue, type: 1, UserselectedAmount: invoiceAmount });
        }
     

       // splits.push({ expenseAllocationAccountId: firstAccountValue, amount: 0, type: 0 });
        
        onChange(splits);
    }

    onRemove(index) {
        const { splits, onChange } = this.props;

        onChange(splits.splice(index, 1));
    }

    renderSplits() {
        const { splits, isDisabled, invoiceAmount } = this.props;
        const { accountOptions, splitOptions } = this.state;
        
        var splitNodes = splits !== null ? splits.map((split, i) => {
            return <ExpenseAllocationSplit key={i} split={split} accountOptions={accountOptions} splitOptions={splitOptions} onChange={this.onSplitChange} index={i} onRemove={this.onRemove} isDisabled={isDisabled} />
        }) : null;


        if(splitNodes != null && !isDisabled) {
            let total = 0;            
            splits.forEach((split) => {
                total += split.type === 0 ? split.amount : (split.amount / 100) * invoiceAmount;   
               
            });
            splitNodes.push(<div className='total-container'><span className='total'>Total</span><span> {NullableCurrency(total)}</span></div>);
        }

        return splitNodes;
    }

    render() {
        const { isDisabled, splits } = this.props;

        return (
            <div>
                {isDisabled && splits &&
                    <Table color='red'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Account</Table.HeaderCell>
                                <Table.HeaderCell>Split</Table.HeaderCell>
                                <Table.HeaderCell>Calculated</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.renderSplits()}
                        </Table.Body>
                    </Table>
                }

                {!isDisabled &&
                    <div>
                        {this.renderSplits()}
                        <Button icon='plus' content='Add Split' onClick={this.onAdd} />
                    </div>
                }
            </div>
        );
    }
}

ExpenseAllocationSplits.propTypes = {
    splits: PropTypes.array.isRequired,
    invoiceAmount: PropTypes.number,
    accounts: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired
};

export default ExpenseAllocationSplits;