import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataForm from '../DataForm';
import { DocumentStatus } from '../../common/enums';
import { Form } from 'semantic-ui-react';
import ExpenseAllocationSplits from './ExpenseAllocationSplits';

class DocumentDataForm extends Component {
    constructor(props) {
        super(props);

        const document = props.document;

        this.state = {
            data: {
                vendorId: document.vendorId,
                departmentId: document.departmentId,
                invoiceNumber: document.invoiceNumber,
                invoiceAmount: document.invoiceAmount,
                invoiceDate: document.invoiceDate,
                purchaseOrder: document.purchaseOrder,
                expenseAllocationSplits: document.expenseAllocationSplits
            }
        };

        this.onValidUpdate = this.onValidUpdate.bind(this);
        this.renderExpenseAllocationSplits = this.renderExpenseAllocationSplits.bind(this);
        this.expenseAllocationSplitsValidator = this.expenseAllocationSplitsValidator.bind(this);
    }

    onValidUpdate(isValid) {
        const { onDataUpdate } = this.props;
        const { data } = this.state;

        if (data.invoiceAmount && data.expenseAllocationSplits !== null) {
            data.expenseAllocationSplits.forEach((eas) => {
                eas.calculated = eas.type === 0 ? eas.amount : (eas.amount / 100) * data.invoiceAmount;
            });
        }

        onDataUpdate(isValid, data);
    }


    renderExpenseAllocationSplits(label, _, splits, isDisabled, onChange) {
        const { document } = this.props;
        const { data } = this.state;
        const { expenseAllocationAccounts } = document;

        return (<Form.Field>
            <label>{label}</label>
            {expenseAllocationAccounts.length > 0 &&
                <ExpenseAllocationSplits accounts={expenseAllocationAccounts}
                    invoiceAmount={data.invoiceAmount}
                    splits={splits}
                    isDisabled={isDisabled}
                    onChange={onChange} />
            }

            {expenseAllocationAccounts.length === 0 &&
                <div>No expense allocation accounts defined</div>
            }
        </Form.Field>);
    }

    expenseAllocationSplitsValidator(splits) {
        const { data } = this.state;
        const { invoiceAmount } = data;
        const { document } = this.props;
        const { expenseAllocationAccounts } = document;

        if (expenseAllocationAccounts.length === 0)
            return false;

        if (splits.length === 0)
            return false;
        
        let total = 0;
        splits.forEach((split) => {
            total += split.type === 0 ? split.amount : (split.amount / 100) * invoiceAmount;
        });

        return total === invoiceAmount;
    }

    render() {
        const { data } = this.state;
        const { document } = this.props;

        const vendors = document.vendors.map((vendor, key) => {
            return {
                key: key + 1,
                text: vendor.name,
                value: vendor.id
            };
        });

        const departments = document.departments.map((department, key) => {
            return {
                key: key + 1,
                text: department.name,
                value: department.id
            }
        });

        const isDisabled = document.status !== DocumentStatus.ApprovalFailed && document.status !== DocumentStatus.AwaitingImport;

        return (
            <DataForm fields={[
                { label: 'Vendor', name: 'vendorId', isRequired: true, type: 'select', options: vendors },
                { label: 'Department', name: 'departmentId', isRequired: true, type: 'select', options: departments },
                { label: 'Invoice Number', name: 'invoiceNumber', isRequired: true, type: 'text' },
                { label: 'Invoice Amount', name: 'invoiceAmount', isRequired: true, type: 'currency' },
                { label: 'Invoice Date', name: 'invoiceDate', isRequired: true, type: 'date' },
                { label: 'Purchase Order', name: 'purchaseOrder', isRequired: false, type: 'text' },
                { label: 'Expense Account Allocations', name: 'expenseAllocationSplits', customRender: this.renderExpenseAllocationSplits, type: 'custom', customValidator: this.expenseAllocationSplitsValidator }
            ]} data={data} onValidUpdate={this.onValidUpdate} disabled={isDisabled} />
        );
    }
}

DocumentDataForm.propTypes = {
    document: PropTypes.object.isRequired,
    onDataUpdate: PropTypes.func.isRequired
};

export default DocumentDataForm;