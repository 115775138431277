import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Table } from 'semantic-ui-react';
import EmptyTableRow from '../../components/EmptyTableRow';
import Indefinte from 'indefinite';
import Inflection from 'inflection';
import DataFormModal from '../DataFormModal';
import RemoveModal from './RemoveModal';

class ListItems extends Component {
    constructor(props) {
        super(props);

        this.onAddSubmit = this.onAddSubmit.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.submitValidation = this.submitValidation.bind(this);
    }

    async onAddSubmit({ name }) {
        const { addAction, organizationId, onUpdate } = this.props;
        await addAction(organizationId, name);
        if (onUpdate) onUpdate();
    }

    submitValidation({ name }) {
        const { items, type } = this.props;

        if (items.filter((item) => item.name === name).length > 0) {
            return `${Inflection.capitalize(Indefinte(Inflection.singularize(type)))} with name '${name}' already exists.`;
        }

        return null;
    }

    async onRemoveClick(e, { item }) {
        const { removeAction, organizationId, onUpdate } = this.props;

        try {
            await removeAction(organizationId, item.id);
            if (onUpdate) onUpdate();
        } catch (message) {
            this.setState({ error: message });
        }
    }

    render() {
        const { type, items, icon } = this.props;

        return (
            <div>
                <Header as='h3'>{Inflection.capitalize(Inflection.pluralize(type))}</Header>
                <Table color='blue'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {items.map((item, i) => {
                            return (<Table.Row key={i}>
                                <Table.Cell>{item.name}</Table.Cell>
                                <Table.Cell>
                                    <RemoveModal type={type} name={item.name} onRemoveClick={this.onRemoveClick} item={item} />
                                </Table.Cell>
                            </Table.Row>);
                        })}

                        {items.length === 0 &&
                            <EmptyTableRow tableName={type} colSpan={2} />
                        }
                    </Table.Body>
                </Table>
                <div>
                    <DataFormModal
                        type={type}
                        onSubmit={this.onAddSubmit}
                        action='add'
                        icon={icon}
                        submitValidation={this.submitValidation}
                        fields={[{ label: 'Name', name: 'name', isRequired: true }]} />
                </div>
            </div>
        );
    }
}

ListItems.propTypes = {
    type: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    icon: PropTypes.string.isRequired,
    addAction: PropTypes.func.isRequired,
    removeAction: PropTypes.func.isRequired,
    organizationId: PropTypes.string.isRequired,
    onUpdate: PropTypes.func
};

export default ListItems;