import * as types from './actionTypes';
import { api } from './api';

export function getDocument(organizationId, documentId) {
    return api({
        types: [types.DOCUMENT__GET_REQUEST, types.DOCUMENT__GET_SUCCESS, types.DOCUMENT__GET_FAILURE],
        uri: `/api/organizations/${organizationId}/documents/${documentId}`,
        config: {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            }
        },
        payload: { organizationId, documentId }
    });
}

export function saveDocument(organizationId, documentId, document) {   
    return api({
        types: [types.DOCUMENT__SAVE_REQUEST, types.DOCUMENT__SAVE_SUCCESS, types.DOCUMENT__SAVE_FAILURE],
        uri: `/api/organizations/${organizationId}/documents/${documentId}`,
        config: {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(document)
        },
        payload: { document }
    });
}

export function saveAndImportDocument(organizationId, documentId, document) {
    return api({
        types: [types.DOCUMENT__SAVE_AND_IMPORT_REQUEST, types.DOCUMENT__SAVE_AND_IMPORT_SUCCESS, types.DOCUMENT__SAVE_AND_IMPORT_FAILURE],
        uri: `/api/organizations/${organizationId}/documents/${documentId}/import`,
        config: {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(document)
        },
        payload: { document }
    });
}

export function approveDocument(organizationId, documentId) {
    return api({
        types: [types.DOCUMENT__APPROVE_REQUEST, types.DOCUMENT__APPROVE_SUCCESS, types.DOCUMENT__APPROVE_FAILURE],
        uri: `/api/organizations/${organizationId}/documents/${documentId}/approve`,
        config: {
            method: 'post',
            headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json'
            }
        }
    });
}

export function sendBackDocument(organizationId, documentId, reason, reasonText) {
    const body = {
        reason,
        reasonText
    };

    return api({
        types: [types.DOCUMENT__SENDBACK_REQUEST, types.DOCUMENT__SENDBACK_SUCCESS, types.DOCUMENT__SENDBACK_FAILURE],
        uri: `/api/organizations/${organizationId}/documents/${documentId}/sendback`,
        config: {
            method: 'post',
            headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json'
            },
            body: JSON.stringify( body )
        },
        payload: { body }
    });
}

export function rejectDocument(organizationId, documentId, reason, reasonText) {
    const body = {
        reason,
        reasonText
    };
    
    return api({
        types: [types.DOCUMENT__REJECT_REQUEST, types.DOCUMENT__REJECT_SUCCESS, types.DOCUMENT__REJECT_FAILURE],
        uri: `/api/organizations/${organizationId}/documents/${documentId}/reject`,
        config: {
            method: 'post',
            headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json'
            },
            body: JSON.stringify( body )
        },
        payload: { body }
    });
}

export function emailDocument(organizationId, documentId, emailAddress) {
    const body = {
        emailAddress
    };
    
    return api({
        types: [types.DOCUMENT__EMAIL_REQUEST, types.DOCUMENT__EMAIL_SUCCESS, types.DOCUMENT__EMAIL_FAILURE],
        uri: `/api/organizations/${organizationId}/documents/${documentId}/email`,
        config: {
            method: 'post',
            headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json'
            },
            body: JSON.stringify( body )
        },
        payload: { body }
    });
}