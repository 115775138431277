import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withAuth from '../withAuth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { routerActions } from 'react-router-redux';
import { Dimmer, Loader, Modal, Segment, Header, Button, Form } from 'semantic-ui-react';
import ErrorMessage from '../components/ErrorMessage';
import * as documentActions from '../actions/documentActions';
import PDF from './document/PDF';
import withWindowDimensions from '../withWindowDimensions';
import DocumentDataForm from './document/DocumentDataForm';
import DocumentInformation from '../components/document/DocumentInformation';
import { DocumentStatus, Reason, ReasonText } from '../common/enums';
import { generateOptions } from '../helpers/dropdown';

class DocumentPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            document: null,
            loading: false,
            data: {
                vendorId: null,
                departmentId: null,
                invoiceNumber: '',
                purchaseOrder:'',
                invoiceDate: '',
                invoiceAmount: ''
            },
            isValid: false,
            showEditModal: false,
            reasonModal: {
                open: false,
                title: '',
                action: '',
                onActionClick: null,
                actionColor: 'green',
                reason: 0,
                reasonText: ''
            },
            error: null
        };

        this.onEditClick = this.onEditClick.bind(this);
        this.onEditClose = this.onEditClose.bind(this);
        this.onDataUpdate = this.onDataUpdate.bind(this);
        this.save = this.save.bind(this);
        this.saveAndImport = this.saveAndImport.bind(this);
        this.approve = this.approve.bind(this);
        this.sendBack = this.sendBack.bind(this);
        this.reject = this.reject.bind(this);
        this.baseAction = this.baseAction.bind(this);
        this.reload = this.reload.bind(this);
        this.onReasonClose = this.onReasonClose.bind(this);
        this.showSendBackModal = this.showSendBackModal.bind(this);
        this.showRejectModal = this.showRejectModal.bind(this);
        this.renderReasonModal = this.renderReasonModal.bind(this);
        this.onReasonChange = this.onReasonChange.bind(this);
        this.onReasonTextChange = this.onReasonTextChange.bind(this);
        this.onSendEmail = this.onSendEmail.bind(this);
    }

    async componentDidMount() {
        await this.reload();
    }

    async reload() {
        const { organizationId, documentId, actions } = this.props;
        const { data } = this.state;
        try {
            this.setState({ loading: true });
            const result = await actions.getDocument(organizationId, documentId);

            this.setState({
                loading: false,
                document: result.response,
                data: {
                    ...data,
                    vendorId: result.response.vendorId,
                    departmentId: result.response.departmentId,
                    invoiceNumber: result.response.invoiceNumber,
                    purchaseOrder: result.response.purchaseOrder,
                    invoiceDate: result.response.invoiceDate,
                    invoiceAmount: result.response.invoiceAmount
                }
            });

        } catch (error) {
            this.setState({ loading: false, error: error.message });
        }
    }

    async baseAction(func) {
        try {
            this.setState({ loading: true, error: null });
            await func();
            this.setState({ loading: false, showEditModal: false });
            await this.reload();
        } catch (error) {
            this.setState({ loading: false, error: error.message });
        }
    }

    async save() {
        const { actions, organizationId, documentId } = this.props;
        const { data } = this.state;

        await this.baseAction(() => actions.saveDocument(organizationId, documentId, data));
    }

    async saveAndImport() {
        const { actions, organizationId, documentId } = this.props;
        const { data } = this.state;

        await this.baseAction(() => actions.saveAndImportDocument(organizationId, documentId, data));
    }

    async approve() {
        const { actions, organizationId, documentId } = this.props;
        await this.baseAction(() => actions.approveDocument(organizationId, documentId));
    }

    async sendBack() {
        const { actions, organizationId, documentId } = this.props;
        const { reason, reasonText } = this.state.reasonModal;

        this.setState({ reasonModal: { ...this.state.reasonModal, open: false } });
        await this.baseAction(() => actions.sendBackDocument(organizationId, documentId, reason, reasonText));
    }

    async reject() {
        const { actions, organizationId, documentId } = this.props;
        const { reason, reasonText } = this.state.reasonModal;

        this.setState({ reasonModal: { ...this.state.reasonModal, open: false } });
        await this.baseAction(() => actions.rejectDocument(organizationId, documentId, reason, reasonText));
    }

    showSendBackModal() {
        const { reasonModal } = this.state;

        this.setState({ reasonModal: { ...reasonModal, open: true, title: 'Send Back Reason', action: 'Send Back', onActionClick: this.sendBack, actionColor: 'orange', reason: 0, reasonText: '' } });
    }

    showRejectModal() {
        const { reasonModal } = this.state;

        this.setState({ reasonModal: { ...reasonModal, open: true, title: 'Reject Reason', action: 'Reject', onActionClick: this.reject, actionColor: 'red', reason: 0, reasonText: '' } });
    }

    getStateButtons() {
        const { document, isValid } = this.state;

        const buttons = [];

        const saveButton = {
            content: 'Save',
            onClick: this.save
        };

        switch (document.status) {
            case DocumentStatus.AwaitingImport:
                buttons.push(saveButton);
                buttons.push({ content: 'Save & Import', onClick: this.saveAndImport, positive: true });
                break;
            case DocumentStatus.ApprovalFailed:
                buttons.push(saveButton);
                buttons.push({ content: 'Save & Resubmit', onClick: this.saveAndImport, positive: true });
                break;
            case DocumentStatus.AwaitingApproval:
                buttons.push({ content: 'Approve', onClick: this.approve, positive: true });
                buttons.push({ content: 'Send Back', onClick: this.showSendBackModal, color: 'orange' });
                buttons.push({ content: 'Reject', onClick: this.showRejectModal, negative: true });
                break;
            default:
                break;
        }

        return buttons.map((button, i) => {
            return <Button key={i} {...button} disabled={!isValid} />;
        });
    }

    onEditClick() {
        this.setState({ showEditModal: true });
    }

    onEditClose() {
        this.setState({ showEditModal: false });
    }

    onDataUpdate(isValid, data) {
        this.setState({ isValid, data });
    }

    onReasonClose() {
        const { reasonModal } = this.state;

        this.setState({ reasonModal: { ...reasonModal, open: false } })
    }

    onReasonChange(e, { value }) {
        const { reasonModal } = this.state;
        this.setState({ reasonModal: { ...reasonModal, isValid: reasonModal.reasonText !== '', reason: value } });
    }

    onReasonTextChange(e, { value }) {
        const { reasonModal } = this.state;
        this.setState({ reasonModal: { ...reasonModal, isValid: value !== '', reasonText: value } });
    }

    renderFormContent(document, error) {
        const dataForm = document ? <DocumentDataForm document={document} onDataUpdate={this.onDataUpdate} /> : null;

        return (<div>
            <ErrorMessage error={error} />
            {dataForm}
        </div>);
    }

    renderReasonModal(reasonModal) {
        const { open, title, action, onActionClick, actionColor, reason, reasonText, isValid } = reasonModal;
        const options = generateOptions(Reason, ReasonText);

        return (<Modal
            open={open}
            onClose={this.onReasonClose}
            size='small'
            centered={true}
            closeIcon={true}
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Dropdown options={options} value={reason} label='Reason' onChange={this.onReasonChange} />
                    <Form.TextArea label='Notes' value={reasonText} onChange={this.onReasonTextChange} />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color={actionColor} onClick={onActionClick} disabled={!isValid}>{action}</Button>
                <Button onClick={this.onReasonClose}>Cancel</Button>
            </Modal.Actions>
        </Modal>);
    }

    onSendEmail({ emailAddress }) {
        const { organizationId, documentId, actions } = this.props;

        actions.emailDocument(organizationId, documentId, emailAddress);
    }

    render() {
        const { loading, document, error, showEditModal, reasonModal } = this.state;
        const { windowWidth } = this.props;

        const showEditPane = windowWidth >= 1200;

        const dataFormButtons = document ? <div className='button-container'>
            {this.getStateButtons()}
        </div> : null;

        return (
            <div className='document-page'>
                {loading && !showEditModal &&
                    <Dimmer active>
                        <Loader>Loading</Loader>
                    </Dimmer>
                }

                <div className='left'>
                    {document && document.base64 &&
                        <PDF document={document} showEdit={!showEditPane} onEditClick={this.onEditClick} onSendEmail={this.onSendEmail} />
                    }
                </div>

                {showEditPane && document &&
                    <div className='right'>
                        <DocumentInformation document={document} />
                        <Segment>
                            <Header>Document Metadata</Header>
                            {this.renderFormContent(document, error)}
                            {dataFormButtons}
                        </Segment>
                    </div>
                }

                {this.renderReasonModal(reasonModal)}

                <Modal
                    open={showEditModal}
                    onClose={this.onEditClose}
                    size='large'
                    centered={false}
                    closeIcon={true}>

                    <Modal.Header>Edit Document Metadata</Modal.Header>
                    <Modal.Content>
                        <div>
                            {loading && showEditModal &&
                                <Dimmer active>
                                    <Loader>Loading</Loader>
                                </Dimmer>
                            }
                            {this.renderFormContent(document, error)}
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        {dataFormButtons}
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

DocumentPage.propTypes = {
    organizationId: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
    windowWidth: PropTypes.number.isRequired
};

const mapStateToProps = (state, ownProps) => {
    return {
        organizationId: state.session.organization.id,
        ...ownProps.match.params
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: {
        ...bindActionCreators(routerActions, dispatch),
        ...bindActionCreators(documentActions, dispatch)
    }
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(withWindowDimensions(DocumentPage)));