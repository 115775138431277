import React from 'react';
import PropTypes from 'prop-types';

const AppLogo = ({ wide, color }) => {
    return (
        <div className={!!wide ? 'logo-container wide' : 'logo-container'}>
            <div className={!!color ? 'logo color' : 'logo'} />
        </div>
    );
};

AppLogo.propTypes = {
    wide: PropTypes.bool,
    color: PropTypes.bool
};

export default AppLogo;