import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Form } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { NullableDate, NullableCurrency } from '../common/formatting';
import Moment from 'moment';

class DataForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: { ...props.data }
        };

        this.createFormField = this.createFormField.bind(this);
        this.onNumberFormatChange = this.onNumberFormatChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    componentDidMount() {
        this.validateForm();
    }

    createReadOnlyField(label, text, key) {
        return (<Form.Field key={key}>
            <label>{label}</label>
            <div>{text}</div>
        </Form.Field>);
    }

    createFormField({ label, name, type, options, customRender }, i) {
        const { data, disabled } = this.props;

        const isDisabled = !!disabled;
        switch (type) {
            case 'select':
                {
                    if (isDisabled) {
                        const option = options.find((opt) => opt.value = data[name]);
                        return this.createReadOnlyField(label, option ? option.text : '', i);
                    }

                    return (<Form.Select
                        key={i}
                        placeholder={label}
                        label={label}
                        name={name}
                        value={data[name] || ''}
                        options={options}
                        onChange={this.onChange}
                        disabled={isDisabled} />);
                }
            case 'currency':
                {
                    if (isDisabled) {
                        return this.createReadOnlyField(label, NullableCurrency(data[name]), i);
                    }

                    return (<Form.Input
                        placeholder={label}
                        key={i}
                        label={label}
                        name={name}
                        value={data[name] || ''}
                        onValueChange={(values) => this.onNumberFormatChange(name, values.floatValue)}
                        control={NumberFormat}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        disabled={isDisabled} />);
                }
            case 'date':
                {
                    if (isDisabled) {
                        return this.createReadOnlyField(label, NullableDate(data[name]), i);
                    }

                    return (<DateInput
                        placeholder={label}
                        key={i} label={label}
                        name={name}
                        value={data[name] ? Moment(data[name]).format('LL') : ''}
                        onChange={this.onChange}
                        closable={true}
                        closeOnMouseLeave={false}
                        dateFormat='LL'
                        disabled={isDisabled} />);
                }
            case 'custom':
                {
                    return (<div key={i}>{customRender(label, name, data[name], isDisabled, (value) => this.onChange(name, value))}</div>);
                }
            default:
                {
                    if (isDisabled) {
                        return this.createReadOnlyField(label, data[name], i);
                    }

                    return (<Form.Input placeholder={label}
                        key={i}
                        label={label}
                        name={name}
                        value={data[name] || ''}
                        onChange={this.onChange}
                        disabled={isDisabled} />);
                }
        }
    }

    onNumberFormatChange(name, value) {
        this.onChange(null, { name, value });
    }

    validateForm() {
        const { onValidUpdate } = this.props;
        const { data } = this.state;

        const isValid = this.isFormValid(data);
        if (onValidUpdate) onValidUpdate(isValid);
    }

    isFormValid(data) {
        const { fields } = this.props;

        const numberOfInvalidFields = fields.filter((field) => field.isRequired && (data[field.name] === '' || data[field.name] == null)).length;
        const customValidatorInvalidFields = fields.filter((field) => field.customValidator && !field.customValidator(data[field.name])).length;

        return ((numberOfInvalidFields + customValidatorInvalidFields) === 0);
    }

    onChange(_, { name, value }) {
        const { data } = this.props;
        data[name] = value;
        this.setState({ data }, () => this.validateForm());
    }

    render() {
        const { fields } = this.props;

        return (
            <Form>
                {fields.map((field, i) => {
                    return this.createFormField(field, i);
                })}
            </Form>
        );
    }
}

DataForm.propTypes = {
    fields: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    onValidUpdate: PropTypes.func,
    disabled: PropTypes.bool
};

export default DataForm;