import React, { Component } from 'react';
import { Form, List, Icon, Input, Message, Loader, Dimmer } from 'semantic-ui-react';
import * as userActions from '../actions/userActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            username: '',
            password: '',
            error: null,
            usernameExists: false,
            passwordExists: false,
            organizations: props.organizations || [],
            fullOrganizations: props.organizations || [],
            authenticated: false || props.authenticated
        };

        this.onUsernameChange = this.onUsernameChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onOrganizationClick = this.onOrganizationClick.bind(this);
        this.filterOrganizations = this.filterOrganizations.bind(this);
        this.selectOrganization = this.selectOrganization.bind(this);
    }

    onUsernameChange(e) {
        this.setState({ username: e.target.value, usernameExists: e.target.value.length > 0 });
    }

    onPasswordChange(e) {
        this.setState({ password: e.target.value, passwordExists: e.target.value.length > 0 });
    }

    async onSubmit() {
        const { username, password } = this.state;
        const { actions } = this.props;
        this.setState({ error: null, loading: true });
        try {
            const loginResult = await actions.authenticate(username, password);
            this.setState({ loading: false });
            if (loginResult.response.token) {
                const organizations = loginResult.response.organizations;

                if(organizations.length === 1) {
                    await this.selectOrganization(organizations[0]);
                } else {
                    this.setState({ authenticated: true, organizations, fullOrganizations: organizations });
                }
            }
        } catch (error) {
            this.setState({ loading: false, error: error.message, password: '', organizations: [] });
        }
    }
    async filterOrganizations(event)
    {
        const {fullOrganizations} = this.state;        
        const organizations = fullOrganizations.filter(f => {            
            return f.id.toString().indexOf(event.target.value.toLowerCase()) !== -1 || f.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
        });        
        this.setState({organizations});
    }
    async selectOrganization(organization) {
        const { actions } = this.props;
        const { from } = { ...{ from: { pathname: '/' } }, ...this.props.location.state };
        await actions.login(organization);
        actions.push(from.pathname);
    }    

    async onOrganizationClick(e, { index }) {
        const { organizations } = this.state;
        const organization = organizations[index];

        try {
            this.setState({ loading: true });
            await this.selectOrganization(organization);
        }
        catch (error) {
            this.setState({ loading: false, error: error.message, organizations: [], authenticated: false, username: '', password: '' });
        }
    }

    render() {
        const { username, password, loading, authenticated, organizations, usernameExists, passwordExists, error } = this.state;
        const selectOrganization = organizations.length > 0;

        return (
            <div className='login'>
                <div className='outer'>
                    <div className='inner'>
                        <div className='logo'></div>
                        <h3 className='line-break'>
                            <span className='text'>
                                {!selectOrganization && 'Login to One View'}
                                {selectOrganization && 'Select an Organization'}
                            </span>
                        </h3>
                        {error &&
                            <Message color='red'>{error}</Message>
                        }
                        <div>
                            {loading &&
                                <Dimmer active inverted>
                                    <Loader />
                                </Dimmer>
                            }
                            {(!authenticated || loading) &&
                                <div>
                                    <Form>

                                        <Form.Field>
                                            <Input icon='user' iconPosition='left' placeholder='Username' value={username} onChange={this.onUsernameChange} disabled={loading} />
                                        </Form.Field>
                                        <Form.Field>
                                            <Input icon='key' iconPosition='left' placeholder='Password' value={password} onChange={this.onPasswordChange} disabled={loading} type='password' />
                                        </Form.Field>
                                        <Form.Button onClick={this.onSubmit} disabled={!(usernameExists && passwordExists) || loading}><Icon name='unlock' />Login</Form.Button>
                                    </Form>
                                </div>
                            }
                            {authenticated && <Input size='small' placeholder='Search Organizations...' onChange={this.filterOrganizations}/>}
                            {authenticated &&                            
                                <List animated selection verticalAlign='middle'>
                                    {organizations.map((org, i) => {
                                        return (<List.Item key={i} index={i} onClick={this.onOrganizationClick}>
                                            <List.Content>
                                                <List.Header>{org.name} ({org.id})</List.Header>
                                            </List.Content>
                                        </List.Item>);
                                    })}
                                </List>
                            }
                        </div>
                    </div>
                </div>
            </div>);
    }
}

LoginPage.propTypes = {
    actions: PropTypes.object.isRequired,
    location: PropTypes.object,
    organizations: PropTypes.array
};

const mapStateToProps = (state) => {
    return {
        organizations: state.session.organizations,
        authenticated: !!state.session.token
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: {
        ...bindActionCreators(userActions, dispatch),
        ...bindActionCreators(routerActions, dispatch)
    }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
