import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

const ErrorMessage = ({ error }) => {
    return (
        <div style={{ marginBottom: '10px' }}>
            {error && error !== '' &&
                <Message icon='warning' content={error} color='red' />
            }
        </div>
    );
};

ErrorMessage.propTypes = {
    error: PropTypes.string
};

export default ErrorMessage;