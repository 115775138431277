export default {
    app: {
        dimmer: null,
        systemInfoMessage: [],
    },
    session: {
        token: null,
        refresh: null,
        expires: null,
        organization: null,
        organizations: [],
        username: null
    }
};