import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { sessionSelector } from '../selectors/sessionSelectors';
import moment from 'moment';

const getInitialState = () => {
    const state = JSON.parse(localStorage.getItem('session'));
    if (!state) return null;
    const session = sessionSelector({ session: state });
    const serverNow = moment().add('milliseconds', state.server_offset);
    if (moment(session.refresh_expires) < serverNow) return null;
    return state;
};

export default function sessionReducer(state = getInitialState() || initialState.session, action) {
    switch (action.type) {
        case types.USER__AUTHENTICATE_REQUEST:
        case types.USER__AUTHENTICATE_FAILURE:
            return { ...state, token: null, refresh: null, expires: null, refreshExpires: null, username: null, organization: null, organizations: [] };
        case types.USER__AUTHENTICATE_SUCCESS:
            return { ...state, ...action.response, username: action.payload.username };
        case types.USER__LOGIN_SUCCESS:
            const newSession = { ...state, organization: action.response };
            localStorage.setItem('session', JSON.stringify(newSession));
            return newSession;
        case types.USER__LOGOUT: {
            localStorage.removeItem('session');
            return { ...initialState.session };
        }
        case types.SESSION__REFRESH_TOKEN_REQUEST:
        case types.SESSION__REFRESH_TOKEN_FAILURE:
        {
            localStorage.removeItem('session');
            return { ...state, token: null, refreh: null, expires: null, refreshExpires: null };
        }
        case types.SESSION__REFRESH_TOKEN_SUCCESS:
        {
            const newSession = { ...state, ...action.payload };
            localStorage.setItem('session', JSON.stringify(newSession));
            return newSession;
        }
        default:
            return state;
    }
}