import React from 'react';
import PropTypes from 'prop-types';
import { Header, Form, Segment, Message } from 'semantic-ui-react';
import { DocumentStatusText } from '../../common/enums';
import { DateTime } from '../../common/formatting';
import { ReasonText } from '../../common/enums';

const DocumentInformation = ({ document }) => {

    const dateField = (date, title) => {
        return date == null ? null :
            <Form.Field>                
                <label>{title}</label>
                <span>{DateTime(date)}</span>
            </Form.Field>;
    }

    return (
        <Segment>
            <Header>Document Information</Header>
            <Form>
                <Form.Field>
                    <label>Status</label>
                    <span>{DocumentStatusText[document.status]} </span>
                </Form.Field>

                {dateField(document.intakeDateTime, 'Intake Date')}
                {dateField(document.importedDateTime, 'Import Date')}
                {document.primaryApprovedDateTime && document.secondaryApprovedDateTime &&
                    <div>
                        {dateField(document.primaryApprovedDateTime, 'Primary Approved Date')}
                        {dateField(document.secondaryApprovedDateTime, 'Secondary Approved Date')}Î
                    </div>
                }

                {!(document.primaryApprovedDateTime && document.secondaryApprovedDateTime) && document.approvedDateTime &&
                    <div>
                        {dateField(document.approvedDateTime, 'Approved Date')}
                    </div>
                }

                {dateField(document.approvalFailedDate, 'Approval Failed Date')}
                {dateField(document.rejectedDateTime, 'Rejected Date')}

                {document.lastSendBackReason != null &&
                    <Message icon='warning' color='orange' header={`Sent Back - ${ReasonText[document.lastSendBackReason]}`} content={document.lastSendBackReasonText} />
                }

                {document.rejectReason != null &&
                    <Message icon='warning' color='red' header={`Rejected - ${ReasonText[document.rejectReason]}`} content={document.rejectReasonText} />
                }
            </Form>
        </Segment>
    );
};

DocumentInformation.propTypes = {
    document: PropTypes.object.isRequired
};

export default DocumentInformation;