import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Header } from 'semantic-ui-react';
import withAuth from '../../withAuth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { routerActions } from 'react-router-redux';
import EmptyTableRow from '../../components/EmptyTableRow';
import DataFormModal from '../DataFormModal';
import * as configurationActions from '../../actions/configurationActions';
import RemoveModal from './RemoveModal';
import Inflection from 'inflection';
import Numeral from 'numeral';

class ApprovalWorkflows extends Component {
    constructor(props) {
        super(props);

        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onAddSubmit = this.onAddSubmit.bind(this);
    }

    async onAddSubmit({ departmentId, primaryApproverId, secondaryApproverId, threshold }) {
        const { organizationId, onUpdate, actions } = this.props;

        await actions.addApprovalWorkflow(organizationId, departmentId, primaryApproverId, secondaryApproverId, threshold);
        if (onUpdate) onUpdate();
    }

    async onRemoveClick(e, { item }) {
        const { organizationId, actions, onUpdate } = this.props;

        await actions.removeApprovalWorkflow(organizationId, item.id);
        if (onUpdate) onUpdate();
    }

    render() {
        const { approvalWorkflows, departments, users } = this.props;

        const departmentOptions = departments.map((department, key) => {
            return {
                key,
                text: department.name,
                value: department.id
            };
        });


        const userOptions = users.map((user, key) => {
            return {
                key: key + 1,
                text: user.name,
                value: user.id
            };
        });


        const userOptionsWithNull = userOptions.map((user) => user);
        userOptionsWithNull.unshift({ key: 0, text: '', value: null });

        const type = 'approval workflow';
        return (
            <div>
                <Header as='h2'>{Inflection.titleize(Inflection.pluralize(type))}</Header>
                <Table color='red'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Department</Table.HeaderCell>
                            <Table.HeaderCell>Primary</Table.HeaderCell>
                            <Table.HeaderCell>Secondary</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'>Threshold</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {approvalWorkflows.map((approvalWorkflow, i) => {
                            return (<Table.Row key={i}>
                                <Table.Cell>{approvalWorkflow.department.name}</Table.Cell>
                                <Table.Cell>{approvalWorkflow.primaryApprover.name}</Table.Cell>
                                <Table.Cell>{approvalWorkflow.secondaryApprover && approvalWorkflow.secondaryApprover.name}</Table.Cell>
                                <Table.Cell textAlign='right'>{approvalWorkflow.threshold ? Numeral(approvalWorkflow.threshold).format('$0,0.00') : ''}</Table.Cell>
                                <Table.Cell>
                                    <RemoveModal type={type} onRemoveClick={this.onRemoveClick} item={approvalWorkflow} />
                                </Table.Cell>
                            </Table.Row>);
                        })}

                        {approvalWorkflows.length === 0 &&
                            <EmptyTableRow colSpan={5} tableName={type} />
                        }
                    </Table.Body>
                </Table>
                <div>
                    <DataFormModal
                        actionButtonDisabled={departments.length === 0 || users.length === 0}
                        type={type}
                        onSubmit={this.onAddSubmit}
                        action='add'
                        icon='thumbs up'
                        fields={[
                            { label: 'Department', name: 'departmentId', isRequired: true, type: 'select', options: departmentOptions },
                            { label: 'Primary', name: 'primaryApproverId', isRequired: true, type: 'select', options: userOptions },
                            { label: 'Secondary', name: 'secondaryApproverId', type: 'select', options: userOptionsWithNull },
                            { label: 'Threshold', name: 'threshold', type: 'currency' }
                        ]} />
                </div>
            </div>
        );
    }
}

ApprovalWorkflows.propTypes = {
    departments: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    approvalWorkflows: PropTypes.array.isRequired,
    organizationId: PropTypes.string.isRequired,
    onUpdate: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
    return {
        organizationId: state.session.organization.id
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: {
        ...bindActionCreators(routerActions, dispatch),
        ...bindActionCreators(configurationActions, dispatch)
    }
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ApprovalWorkflows));