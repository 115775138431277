import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { appSelector, sessionSelector } from './selectors';
import { withRouter } from 'react-router-dom';
import withWindowDimensions from './withWindowDimensions';
import { bindActionCreators } from 'redux';
import * as userActions from './actions/userActions';
import moment from 'moment';

import ConfigurationPage from './containers/ConfigurationPage';
import DashboardPage from './containers/DashboardPage';
import AppHeader from './components/app/AppHeader';
import AppFooter from './components/app/AppFooter';
import LoginPage from './containers/LoginPage';
import LogoutPage from './containers/LogoutPage';
import DocumentPage from './containers/DocumentPage';
import AppSidebar from './components/app/AppSidebar';


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: !props.isMobileSized
    };

    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onSidebarItemClick = this.onSidebarItemClick.bind(this);
  }

  componentDidUpdate() {
    const { session, actions } = this.props;
    
    const serverNow = moment().add('milliseconds', session.server_offset);
    if (session.authenticated && moment(session.tokenExpires) < serverNow) {
      actions.logout();
    }
  }

  onMenuItemClick() {
    const { sidebarOpen } = this.state;
    this.setState({ sidebarOpen: !sidebarOpen });
  }

  onSidebarItemClick() {
    const { isMobileSized } = this.props;
    if (isMobileSized) this.setState({ sidebarOpen: false });
  }

  render() {
    const { session } = this.props;
    const { sidebarOpen } = this.state;

    const currentPath = window.location.pathname;
    const switching = currentPath.includes("/switch");
    const hideMenu = currentPath.includes("/document");
    const showChrome = session.authenticated && !switching;

    return (
      <div className='app'>
        {showChrome && sidebarOpen && !hideMenu &&
          <AppSidebar onItemClick={this.onSidebarItemClick} />
        }

        <div className="content-container">
          {showChrome &&
            <AppHeader username={session.username} organizationName={session.organization.name} showMenu={!hideMenu} showLogo={!sidebarOpen || hideMenu} onMenuItemClick={this.onMenuItemClick} />
          }

          <div className='content'>
            <div className='inner'>
              <Switch>
                <Route exact path='/' component={DashboardPage} />
                <Route exact path='/dashboard' component={DashboardPage} />
                <Route exact path='/configuration' component={ConfigurationPage} />
                <Route exact path="/document/:documentId" component={DocumentPage} />
                <Route exact path='/login' component={LoginPage} />
                <Route exact path="/logout" component={LogoutPage} />
                <Route exact path="/switch" component={LoginPage} />
              </Switch>
            </div>
          </div>

          {showChrome &&
            <AppFooter />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    app: appSelector(state),
    session: sessionSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(userActions, dispatch)
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withWindowDimensions(App)));