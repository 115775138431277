// USER ACTION TYPES

export const USER__AUTHENTICATE_REQUEST = 'USER__AUTHENTICATE_REQUEST';
export const USER__AUTHENTICATE_SUCCESS = 'USER__AUTHENTICATE_SUCCESS';
export const USER__AUTHENTICATE_FAILURE = 'USER__AUTHENTICATE_FAILURE';

export const USER__GET_ORGANIZATIONS_REQUEST = 'USER__GET_ORGANIZATIONS_REQUEST';
export const USER__GET_ORGANIZATIONS_SUCCESS = 'USER__GET_ORGANIZATIONS_SUCCESS';
export const USER__GET_ORGANIZATIONS_FAILURE = 'USER__GET_ORGANIZATIONS_FAILURE';

export const USER__LOGIN_REQUEST = 'USER__LOGIN_REQUEST';
export const USER__LOGIN_SUCCESS = 'USER__LOGIN_SUCCESS';
export const USER__LOGIN_FAILURE = 'USER__LOGIN_FAILURE';

export const USER__LOGOUT = 'USER__LOGOUT';

//SESSION ACTION TYPES
export const SESSION__REFRESH_TOKEN_REQUEST = 'SESSION__REFRESH_TOKEN_REQUEST';
export const SESSION__REFRESH_TOKEN_SUCCESS = 'SESSION__REFRESH_TOKEN_SUCCESS';
export const SESSION__REFRESH_TOKEN_FAILURE = 'SESSION__REFRESH_TOKEN_FAILURE';

//CONFIGURATION ACTION TYPES
export const CONFIGURATION__ADD_VENDOR_REQUEST = 'CONFIGURATION__ADD_VENDOR_REQUEST';
export const CONFIGURATION__ADD_VENDOR_SUCCESS = 'CONFIGURATION__ADD_VENDOR_SUCCESS';
export const CONFIGURATION__ADD_VENDOR_FAILURE = 'CONFIGURATION__ADD_VENDOR_FAILURE';

export const CONFIGURATION__REMOVE_VENDOR_REQUEST = 'CONFIGURATION__REMOVE_VENDOR_REQUEST';
export const CONFIGURATION__REMOVE_VENDOR_SUCCESS = 'CONFIGURATION__REMOVE_VENDOR_SUCCESS';
export const CONFIGURATION__REMOVE_VENDOR_FAILURE = 'CONFIGURATION__REMOVE_VENDOR_FAILURE';

export const CONFIGURATION__ADD_DEPARTMENT_REQUEST = 'CONFIGURATION__ADD_DEPARTMENT_REQUEST';
export const CONFIGURATION__ADD_DEPARTMENT_SUCCESS = 'CONFIGURATION__ADD_DEPARTMENT_SUCCESS';
export const CONFIGURATION__ADD_DEPARTMENT_FAILURE = 'CONFIGURATION__ADD_DEPARTMENT_FAILURE';

export const CONFIGURATION__REMOVE_DEPARTMENT_REQUEST = 'CONFIGURATION__REMOVE_DEPARTMENT_REQUEST';
export const CONFIGURATION__REMOVE_DEPARTMENT_SUCCESS = 'CONFIGURATION__REMOVE_DEPARTMENT_SUCCESS';
export const CONFIGURATION__REMOVE_DEPARTMENT_FAILURE = 'CONFIGURATION__REMOVE_DEPARTMENT_FAILURE';

export const CONFIGURATION__ADD_EXPENSE_ALLOCATION_ACCOUNT_REQUEST = 'CONFIGURATION__ADD_EXPENSE_ALLOCATION_ACCOUNT_REQUEST';
export const CONFIGURATION__ADD_EXPENSE_ALLOCATION_ACCOUNT_SUCCESS = 'CONFIGURATION__ADD_EXPENSE_ALLOCATION_ACCOUNT_SUCCESS';
export const CONFIGURATION__ADD_EXPENSE_ALLOCATION_ACCOUNT_FAILURE = 'CONFIGURATION__ADD_EXPENSE_ALLOCATION_ACCOUNT_FAILURE';

export const CONFIGURATION__REMOVE_EXPENSE_ALLOCATION_ACCOUNT_REQUEST = 'CONFIGURATION__REMOVE_EXPENSE_ALLOCATION_ACCOUNT_REQUEST';
export const CONFIGURATION__REMOVE_EXPENSE_ALLOCATION_ACCOUNT_SUCCESS = 'CONFIGURATION__REMOVE_EXPENSE_ALLOCATION_ACCOUNT_SUCCESS';
export const CONFIGURATION__REMOVE_EXPENSE_ALLOCATION_ACCOUNT_FAILURE = 'CONFIGURATION__REMOVE_EXPENSE_ALLOCATION_ACCOUNT_FAILURE';

export const CONFIGURATION__ADD_APPROVAL_WORKFLOW_REQUEST = 'CONFIGURATION__ADD_APPROVAL_WORKFLOW_REQUEST';
export const CONFIGURATION__ADD_APPROVAL_WORKFLOW_SUCCESS = 'CONFIGURATION__ADD_APPROVAL_WORKFLOW_SUCCESS';
export const CONFIGURATION__ADD_APPROVAL_WORKFLOW_FAILURE = 'CONFIGURATION__ADD_APPROVAL_WORKFLOW_FAILURE';

export const CONFIGURATION__REMOVE_APPROVAL_WORKFLOW_REQUEST = 'CONFIGURATION__REMOVE_APPROVAL_WORKFLOW_REQUEST';
export const CONFIGURATION__REMOVE_APPROVAL_WORKFLOW_SUCCESS = 'CONFIGURATION__REMOVE_APPROVAL_WORKFLOW_SUCCESS';
export const CONFIGURATION__REMOVE_APPROVAL_WORKFLOW_FAILURE = 'CONFIGURATION__REMOVE_APPROVAL_WORKFLOW_FAILURE';

export const CONFIGURATION__GET_REQUEST = 'CONFIGURATION__GET_REQUEST';
export const CONFIGURATION__GET_SUCCESS = 'CONFIGURATION__GET_SUCCESS';
export const CONFIGURATION__GET_FAILURE = 'CONFIGURATION__GET_FAILURE';

//DASHBOARD ACTION TYPES
export const DASHBOARD__GET_REQUEST = 'DASHBOARD__GET_REQUEST';
export const DASHBOARD__GET_SUCCESS = 'DASHBOARD__GET_SUCCESS';
export const DASHBOARD__GET_FAILURE = 'DASHBOARD__GET_FAILURE';

export const DASHBOARD__GET_NEW_DOCUMENTS_REQUEST = 'DASHBOARD__GET_NEW_DOCUMENTS_REQUEST';
export const DASHBOARD__GET_NEW_DOCUMENTS_SUCCESS = 'DASHBOARD__GET_NEW_DOCUMENTS_SUCCESS';
export const DASHBOARD__GET_NEW_DOCUMENTS_FAILURE = 'DASHBOARD__GET_NEW_DOCUMENTS_FAILURE';

export const DASHBOARD__GET_MY_APPROVAL_DOCUMENTS_REQUEST = 'DASHBOARD__GET_MY_APPROVAL_DOCUMENTS_REQUEST';
export const DASHBOARD__GET_MY_APPROVAL_DOCUMENTS_SUCCESS = 'DASHBOARD__GET_MY_APPROVAL_DOCUMENTS_SUCCESS';
export const DASHBOARD__GET_MY_APPROVAL_DOCUMENTS_FAILURE = 'DASHBOARD__GET_MY_APPROVAL_DOCUMENTS_FAILURE';

export const DASHBOARD__GET_SENT_BACK_DOCUMENTS_REQUEST = 'DASHBOARD__GET_SENT_BACK_DOCUMENTS_REQUEST';
export const DASHBOARD__GET_SENT_BACK_DOCUMENTS_SUCCESS = 'DASHBOARD__GET_SENT_BACK_DOCUMENTS_SUCCESS';
export const DASHBOARD__GET_SENT_BACK_DOCUMENTS_FAILURE = 'DASHBOARD__GET_SENT_BACK_DOCUMENTS_FAILURE';

export const DASHBOARD__GET_REJECTED_DOCUMENTS_REQUEST = 'DASHBOARD__GET_REJECTED_DOCUMENTS_REQUEST';
export const DASHBOARD__GET_REJECTED_DOCUMENTS_SUCCESS = 'DASHBOARD__GET_REJECTED_DOCUMENTS_SUCCESS';
export const DASHBOARD__GET_REJECTED_DOCUMENTS_FAILURE = 'DASHBOARD__GET_REJECTED_DOCUMENTS_FAILURE';

export const DASHBOARD__GET_APPROVED_DOCUMENTS_REQUEST = 'DASHBOARD__GET_APPROVED_DOCUMENTS_REQUEST';
export const DASHBOARD__GET_APPROVED_DOCUMENTS_SUCCESS = 'DASHBOARD__GET_APPROVED_DOCUMENTS_SUCCESS';
export const DASHBOARD__GET_APPROVED_DOCUMENTS_FAILURE = 'DASHBOARD__GET_APPROVED_DOCUMENTS_FAILURE';


//DOCUMENT ACTION TYPES

export const DOCUMENT__GET_REQUEST = 'DOCUMENT__GET_REQUEST';
export const DOCUMENT__GET_SUCCESS = 'DOCUMENT__GET_SUCCESS';
export const DOCUMENT__GET_FAILURE = 'DOCUMENT__GET_FAILURE';

export const DOCUMENT__SAVE_REQUEST = 'DOCUMENT__SAVE_REQUEST';
export const DOCUMENT__SAVE_SUCCESS = 'DOCUMENT__SAVE_SUCCESS';
export const DOCUMENT__SAVE_FAILURE = 'DOCUMENT__SAVE_FAILURE';

export const DOCUMENT__SAVE_AND_IMPORT_REQUEST = 'DOCUMENT__SAVE_AND_IMPORT_REQUEST';
export const DOCUMENT__SAVE_AND_IMPORT_SUCCESS = 'DOCUMENT__SAVE_AND_IMPORT_SUCCESS';
export const DOCUMENT__SAVE_AND_IMPORT_FAILURE = 'DOCUMENT__SAVE_AND_IMPORT_FAILURE';

export const DOCUMENT__APPROVE_REQUEST = 'DOCUMENT__APPROVE_REQUEST';
export const DOCUMENT__APPROVE_SUCCESS = 'DOCUMENT__APPROVE_SUCCESS';
export const DOCUMENT__APPROVE_FAILURE = 'DOCUMENT__APPROVE_FAILURE';

export const DOCUMENT__SENDBACK_REQUEST = 'DOCUMENT__SENDBACK_REQUEST';
export const DOCUMENT__SENDBACK_SUCCESS = 'DOCUMENT__SENDBACK_SUCCESS';
export const DOCUMENT__SENDBACK_FAILURE = 'DOCUMENT__SENDBACK_FAILURE';

export const DOCUMENT__REJECT_REQUEST = 'DOCUMENT__REJECT_REQUEST';
export const DOCUMENT__REJECT_SUCCESS = 'DOCUMENT__REJECT_SUCCESS';
export const DOCUMENT__REJECT_FAILURE = 'DOCUMENT__REJECT_FAILURE';

export const DOCUMENT__EMAIL_REQUEST = 'DOCUMENT__EMAIL_REQUEST';
export const DOCUMENT__EMAIL_SUCCESS = 'DOCUMENT__EMAIL_SUCCESS';
export const DOCUMENT__EMAIL_FAILURE = 'DOCUMENT__EMAIL_FAILURE';