import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItems from './ListItems'
import withAuth from '../../withAuth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { routerActions } from 'react-router-redux';
import * as configurationActions from '../../actions/configurationActions';

class Vendors extends Component {
    render() {
        const { vendors, actions, onUpdate, organizationId } = this.props;

        return (
            <div>
                <ListItems type='vendor'
                    items={vendors}
                    icon='truck'
                    addAction={actions.addVendor}
                    removeAction={actions.removeVendor}
                    onUpdate={onUpdate}
                    organizationId={organizationId} />
            </div>
        );
    }
}

Vendors.propTypes = {
    vendors: PropTypes.array.isRequired,
    onUpdate: PropTypes.func,
    organizationId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
    return {
        organizationId: state.session.organization.id
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: {
        ...bindActionCreators(routerActions, dispatch),
        ...bindActionCreators(configurationActions, dispatch)
    }
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Vendors));