import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Icon, Button, Table } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import { NullableCurrency } from '../../common/formatting';

class ExpenseAllocationSplit extends Component {
    constructor(props) {
        super(props);

        this.onNumberFormatChange = this.onNumberFormatChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.renderReadonly = this.renderReadonly.bind(this);
        this.renderEditable = this.renderEditable.bind(this);
    }

    onNumberFormatChange(name, value) {
        this.onChange(null, { name, value });
    }

    onChange(_, { name, value }) {
        const { split, onChange } = this.props;

        let Conversionvalue = 0;
        let currentvaule = 0;   
        let invoiceAmount = 0;
        currentvaule = split['amount'];
        invoiceAmount = split['UserselectedAmount'];
        split[name] = value;
        if (name === 'type') {

            if (split[name] === 0) {
                
                Conversionvalue = (currentvaule / 100) * invoiceAmount;

            }
            else {

                Conversionvalue = (currentvaule / invoiceAmount) * 100;
              
            }


            split['amount'] = Conversionvalue;
        };


    
        if (onChange) onChange();
    }

    renderReadonly() {
        const { split, accountOptions } = this.props;

        return (
            <Table.Row>
                <Table.Cell>{split.expenseAllocationAccountId && accountOptions.find(a => a.value === split.expenseAllocationAccountId).text}</Table.Cell>
                <Table.Cell>
                    {split.type === 0 &&
                        <div>{NullableCurrency(split.amount)}</div>
                    }
                    {split.type === 1 &&
                        <div>{split.amount}%</div>
                    }
                </Table.Cell>
                <Table.Cell>
                    {NullableCurrency(split.calculated)}
                </Table.Cell>
            </Table.Row>
        );
    }

    renderEditable() {
        const { split, accountOptions, splitOptions, onRemove, index } = this.props;

        return (
            <Form.Group widths='equal'>
                <Form.Select name='expenseAllocationAccountId' placeholder='Expense Allocation Account' value={split.expenseAllocationAccountId} options={accountOptions} onChange={this.onChange} />
                <Form.Select name='type' placeholder='Split Type' value={split.type} options={splitOptions} onChange={this.onChange} />

                {split.type === 0 &&
                    <Form.Input
                        placeholder='Amount'
                        value={split.amount}
                        onValueChange={({ floatValue }) => this.onNumberFormatChange('amount', floatValue)}
                        control={NumberFormat}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true} />
                }

                {split.type === 1 &&
                    <Form.Input
                        placeholder='Percentage'
                        value={split.amount}
                        onValueChange={({ floatValue }) => this.onNumberFormatChange('amount', floatValue)}
                        control={NumberFormat}
                        suffix={'%'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        isAllowed={({ value }) => value <= 100 && value >= 0} />
                }

                <Form.Field className='total'>
                    {NullableCurrency(split.calculated)}
                </Form.Field>
                <Button className='form-button' floated='right' icon onClick={() => onRemove(index)}><Icon name='trash' /></Button>
            </Form.Group>
        );
    }

    render = () => this.props.isDisabled ? this.renderReadonly() : this.renderEditable();
}

ExpenseAllocationSplit.propTypes = {
    split: PropTypes.object.isRequired,
    accountOptions: PropTypes.array.isRequired,
    splitOptions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,    
};

export default ExpenseAllocationSplit;