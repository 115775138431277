import * as types from './actionTypes';
import { api } from './api';

export function addVendor(organizationId, name) {
    const body = {
        name
    };
    return api({
        types: [types.CONFIGURATION__ADD_VENDOR_REQUEST, types.CONFIGURATION__ADD_VENDOR_SUCCESS, types.CONFIGURATION__ADD_VENDOR_FAILURE],
        uri: `/api/organizations/${organizationId}/configuration/vendors`,
        config: {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        },
        payload: { body }
    });
}

export function removeVendor(organizationId, id) {
    return api({
        types: [types.CONFIGURATION__REMOVE_VENDOR_REQUEST, types.CONFIGURATION__REMOVE_VENDOR_SUCCESS, types.CONFIGURATION__REMOVE_VENDOR_FAILURE],
        uri: `/api/organizations/${organizationId}/configuration/vendors/${id}`,
        config: {
            method: 'delete',
            headers: {
                'Accept': 'application/json'
            }
        },
        payload: { 
            organizationId, 
            id
        }
    });
}

export function addDepartment(organizationId, name) {
    const body = {
        name
    };
    return api({
        types: [types.CONFIGURATION__ADD_DEPARTMENT_REQUEST, types.CONFIGURATION__ADD_DEPARTMENT_SUCCESS, types.CONFIGURATION__ADD_DEPARTMENT_FAILURE],
        uri: `/api/organizations/${organizationId}/configuration/departments`,
        config: {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        },
        payload: { body }
    });
}

export function removeDepartment(organizationId, id) {
    return api({
        types: [types.CONFIGURATION__REMOVE_DEPARTMENT_REQUEST, types.CONFIGURATION__REMOVE_DEPARTMENT_SUCCESS, types.CONFIGURATION__REMOVE_DEPARTMENT_FAILURE],
        uri: `/api/organizations/${organizationId}/configuration/departments/${id}`,
        config: {
            method: 'delete',
            headers: {
                'Accept': 'application/json'
            }
        },
        payload: { id }
    });
}

export function addExpenseAllocationAccount(organizationId, accountNumber, name) {
    const body = {
        accountNumber,
        name
    };

    return api({
        types: [types.CONFIGURATION__ADD_EXPENSE_ALLOCATION_ACCOUNT_REQUEST, types.CONFIGURATION__ADD_EXPENSE_ALLOCATION_ACCOUNT_SUCCESS, types.CONFIGURATION__ADD_EXPENSE_ALLOCATION_ACCOUNT_FAILURE],
        uri: `/api/organizations/${organizationId}/configuration/expenseallocationaccounts`,
        config: {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        },
        payload: { body }
    });
}

export function removeExpenseAllocationAccount(organizationId, id) {
    return api({
        types: [types.CONFIGURATION__REMOVE_EXPENSE_ALLOCATION_ACCOUNT_REQUEST, types.CONFIGURATION__REMOVE_EXPENSE_ALLOCATION_ACCOUNT_SUCCESS, types.CONFIGURATION__REMOVE_EXPENSE_ALLOCATION_ACCOUNT_FAILURE],
        uri: `/api/organizations/${organizationId}/configuration/expenseallocationaccounts/${id}`,
        shouldCallAPI: () => true,
        config: {
            method: 'delete',
            headers: {
                'Accept': 'application/json'
            }
        },
        payload: { 
            organizationId, 
            id 
        }
    });
}

export function addApprovalWorkflow(organizationId, departmentId, primaryApproverId, secondaryApproverId, threshold) {
    const body = {
        departmentId,
        primaryApproverId,
        secondaryApproverId,
        threshold
    };

    return api({
        types: [types.CONFIGURATION__ADD_APPROVAL_WORKFLOW_REQUEST, types.CONFIGURATION__ADD_APPROVAL_WORKFLOW_SUCCESS, types.CONFIGURATION__ADD_APPROVAL_WORKFLOW_FAILURE],
        uri: `/api/organizations/${organizationId}/configuration/approvalworkflows`,
        config: {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        },
        payload: { body }
    });
}

export function removeApprovalWorkflow(organizationId, id) {
    return api({
        types: [types.CONFIGURATION__REMOVE_APPROVAL_WORKFLOW_REQUEST, types.CONFIGURATION__REMOVE_APPROVAL_WORKFLOW_SUCCESS, types.CONFIGURATION__REMOVE_APPROVAL_WORKFLOW_FAILURE],
        uri: `/api/organizations/${organizationId}/configuration/approvalworkflows/${id}`,
        config: {
            method: 'delete',
            headers: {
               'Accept': 'application/json'
            }
        },
        payload: { 
            organizationId, 
            id 
        }
    });
}

export function getConfiguration(organizationId) {
    return api({
        types: [types.CONFIGURATION__GET_REQUEST, types.CONFIGURATION__GET_SUCCESS, types.CONFIGURATION__GET_FAILURE],
        uri: `/api/organizations/${organizationId}/configuration`,
        config: {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            }
        }
    });
}