import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Icon } from 'semantic-ui-react';

const RemoveModal = ({ type, name, onRemoveClick, item }) => {
    let content = `Delete ${type.toLowerCase()}`;
    if (name) {
        content += ` '${name}'`;
    }
    content += '?';
    return (
        <Modal trigger={<Button floated='right' icon><Icon name='trash' /></Button>}
            header='Are you sure?'
            content={content}
            actions={['No', { key: 'yes', content: 'Yes', color: 'red', onClick: onRemoveClick, item }]} />
    );
};

RemoveModal.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    onRemoveClick: PropTypes.func.isRequired,
    item: PropTypes.any.isRequired
};

export default RemoveModal;