import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import Inflection from 'inflection';

const EmptyTableRow = ({ tableName, colSpan }) => {
    return (
        <Table.Row>
            <Table.Cell colSpan={colSpan} style={{ textAlign: 'center' }}>No {Inflection.titleize(Inflection.pluralize(tableName))}</Table.Cell>
        </Table.Row>
    );
};

EmptyTableRow.propTypes = {
    tableName: PropTypes.string.isRequired,
    colSpan: PropTypes.number.isRequired
};

export default EmptyTableRow;