import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Icon, Header, Dimmer, Loader } from 'semantic-ui-react';
import Inflection from 'inflection';
import ErrorMessage from '../components/ErrorMessage';
import DataForm from './DataForm';

class DataFormModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            error: '',
            loading: false,
            data: {},
            saveEnabled: false
        };

        this.onActionClick = this.onActionClick.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onValidUpdate = this.onValidUpdate.bind(this);
    }

    onOpen() {
        this.setState({ open: true });
    }

    onClose() {
        this.setState({ open: false, data: {}, error: '', saveEnabled: false, loading: false });
    }

    async onActionClick() {
        const { submitValidation, onSubmit } = this.props;
        const { data } = this.state;

        let error = null;
        if (submitValidation) error = submitValidation(data);

        if (error) {
            this.setState({ error })
        } else {
            try {
                this.setState({ loading: true, error: '' });
                await onSubmit(data);
                this.setState({ loading: false });
                this.onClose();
            } catch (error) {
                this.setState({ error: error.message, loading: false });
            }
        }
    }

    async onKeyPress(e) {
        if (e.charCode === 13) {
            await this.onActionClick();
        }
    }

    onValidUpdate(isValid) {
        this.setState({ saveEnabled: isValid });
    }

    render() {
        const { type, fields, icon, action, actionButtonDisabled } = this.props;
        const { open, error, loading, saveEnabled, data } = this.state;
        let { trigger } = this.props;

        if(!trigger) {
            trigger = <Button disabled={actionButtonDisabled} floated='right' icon labelPosition='left' primary><Icon name={icon} />{Inflection.capitalize(action)} {Inflection.titleize(type)}</Button>;
        }

        return (
            <Modal trigger={trigger}
                size='tiny'
                open={open}
                onOpen={this.onOpen}
                onClose={this.onClose}>
                <Header icon={icon} content={`${Inflection.capitalize(action)} ${Inflection.titleize(type)}`} />
                <Modal.Content>
                    {loading &&
                        <Dimmer active>
                            <Loader size='small' />
                        </Dimmer>
                    }
                    <ErrorMessage error={error} />
                    <DataForm fields={fields} data={data} onValidUpdate={this.onValidUpdate} />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.onClose}><Icon name='cancel' /> Cancel</Button>
                    <Button color='blue' onClick={this.onActionClick} disabled={!saveEnabled} onKeyPress={this.onKeyPress}><Icon name={action} /> {Inflection.capitalize(action)}</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

DataFormModal.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    action: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    fields: PropTypes.array.isRequired,
    icon: PropTypes.string.isRequired,
    submitValidation: PropTypes.func,
    children: PropTypes.node,
    actionButtonDisabled: PropTypes.bool,
    trigger: PropTypes.node
};

export default DataFormModal;