import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Header } from 'semantic-ui-react';
import withAuth from '../../withAuth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { routerActions } from 'react-router-redux';
import EmptyTableRow from '../../components/EmptyTableRow';
import * as configurationActions from '../../actions/configurationActions';
import DataFormModal from '../DataFormModal';
import RemoveModal from './RemoveModal';
import Inflection from 'inflection';

class ExpenseAllocationAccounts extends Component {
    constructor(props) {
        super(props);

        this.onAddSubmit = this.onAddSubmit.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onAddSubmitValidation = this.onAddSubmitValidation.bind(this);
    }

    async onAddSubmit({ accountNumber, name }) {
        const { organizationId, actions, onUpdate } = this.props;
        await actions.addExpenseAllocationAccount(organizationId, accountNumber, name);
        if (onUpdate) onUpdate();
    }

    onAddSubmitValidation({ name }) {
        const { expenseAllocationAccounts } = this.props;

        if (expenseAllocationAccounts.filter((item) => item.name === name).length > 0) {
            return `An expense allocation account with name '${name}' already exists.`;
        }
        return null;
    }

    async onRemoveClick(e, { item }) {
        const { organizationId, actions, onUpdate } = this.props;

        await actions.removeExpenseAllocationAccount(organizationId, item.id);
        if (onUpdate) onUpdate();
    }

    render() {
        const { expenseAllocationAccounts } = this.props;
        const type = 'expense allocation account';
        return (
            <div>
                <Header as='h2'>{Inflection.titleize(Inflection.pluralize(type))}</Header>
                <Table color='purple'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={3}>Account #</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {expenseAllocationAccounts.map((expenseAllocationAccount, i) => {
                            return (<Table.Row key={i}>
                                <Table.Cell>{expenseAllocationAccount.accountNumber}</Table.Cell>
                                <Table.Cell>{expenseAllocationAccount.name}</Table.Cell>
                                <Table.Cell>
                                    <RemoveModal type={type} name={expenseAllocationAccount.accountNumber} onRemoveClick={this.onRemoveClick} item={expenseAllocationAccount} />
                                </Table.Cell>
                            </Table.Row>);
                        })}

                        {expenseAllocationAccounts.length === 0 &&
                            <EmptyTableRow tableName={Inflection.titleize(type)} colSpan={3} />
                        }
                    </Table.Body>
                </Table >
                <div>
                    <DataFormModal
                        onSubmit={this.onAddSubmit}
                        type={type}
                        action='add'
                        icon='dollar sign'
                        submitValidation={this.onAddSubmitValidation}
                        fields={
                            [
                                { label: 'Account #', name: 'accountNumber', isRequired: true },
                                { label: 'Name', name: 'name', isRequired: true }
                            ]
                        } />
                </div>
            </div >
        );
    }
}

ExpenseAllocationAccounts.propTypes = {
    expenseAllocationAccounts: PropTypes.array.isRequired,
    organizationId: PropTypes.string.isRequired,
    onUpdate: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
    return {
        organizationId: state.session.organization.id
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: {
        ...bindActionCreators(routerActions, dispatch),
        ...bindActionCreators(configurationActions, dispatch)
    }
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ExpenseAllocationAccounts));