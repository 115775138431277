import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withAuth from '../withAuth';
import { connect } from 'react-redux';
import * as userActions from '../actions/userActions';
import { Redirect } from 'react-router-dom';
import { sessionSelector } from '../selectors';
import { bindActionCreators } from 'redux';

class LogoutPage extends Component {
    componentDidMount() {
        const { actions, session } = this.props;

        if (session.authenticated) {
            actions.logout();
        }
    }

    render() {
        const { session } = this.props;
        if (!session.authenticated) {
            return <Redirect to='/' />;
        }

        return (
            <div></div>
        );
    }
}

LogoutPage.propTypes = {
    session: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        session: sessionSelector(state)
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: {
        ...bindActionCreators(userActions, dispatch)
    }
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(LogoutPage));