import * as types from './actionTypes';
import { api } from './api';

export function getNewDocuments(organizationId, page, pageSize, startDate, endDate) {
    return api({
        types: [types.DASHBOARD__GET_NEW_DOCUMENTS_REQUEST, types.DASHBOARD__GET_NEW_DOCUMENTS_SUCCESS, types.DASHBOARD__GET_NEW_DOCUMENTS_FAILURE],
        uri: `/api/organizations/${organizationId}/dashboard/documents/new?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`,
        config: {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            }
        },
        payload: {
            organizationId
        }
    });
}

export function getMyApprovalDocuments(organizationId, page, pageSize, startDate, endDate) {
    return api({
        types: [types.DASHBOARD__GET_MY_APPROVAL_DOCUMENTS_REQUEST, types.DASHBOARD__GET_MY_APPROVAL_DOCUMENTS_SUCCESS, types.DASHBOARD__GET_MY_APPROVAL_DOCUMENTS_FAILURE],
        uri: `/api/organizations/${organizationId}/dashboard/documents/myapprovals?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`,
        shouldCallAPI: () => true,
        config: {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            }
        },
        payload: {
            organizationId
        }
    });
}

export function getSentBackDocuments(organizationId, page, pageSize, startDate, endDate) {
    return api({
        types: [types.DASHBOARD__GET_SENT_BACK_DOCUMENTS_REQUEST, types.DASHBOARD__GET_SENT_BACK_DOCUMENTS_SUCCESS, types.DASHBOARD__GET_SENT_BACK_DOCUMENTS_FAILURE],
        uri: `/api/organizations/${organizationId}/dashboard/documents/sentback?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`,
        config: {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            },
        },
        payload: {
            organizationId
        }
    });
}

export function getRejectedDocuments(organizationId, page, pageSize, startDate, endDate) {
    return api({
        types: [types.DASHBOARD__GET_REJECTED_DOCUMENTS_REQUEST, types.DASHBOARD__GET_REJECTED_DOCUMENTS_SUCCESS, types.DASHBOARD__GET_REJECTED_DOCUMENTS_FAILURE],
        uri: `/api/organizations/${organizationId}/dashboard/documents/rejected?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`,
        shouldCallAPI: () => true,
        config: {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            }
        },
        payload: {
            organizationId
        }
    });
}

export function getApprovedDocuments(organizationId, page, pageSize, startDate, endDate) {
    return api({
        types: [types.DASHBOARD__GET_APPROVED_DOCUMENTS_REQUEST, types.DASHBOARD__GET_APPROVED_DOCUMENTS_SUCCESS, types.DASHBOARD__GET_APPROVED_DOCUMENTS_FAILURE],
        uri: `/api/organizations/${organizationId}/dashboard/documents/approved?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`,
        shouldCallAPI: () => true,
        config: {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            }
        },
        payload: {
            organizationId
        }
    });
}

export function getDashboard(organizationId) {
    return api({
        types: [types.DASHBOARD__GET_REQUEST, types.DASHBOARD__GET_SUCCESS, types.DASHBOARD__GET_FAILURE],
        uri: `/api/organizations/${organizationId}/dashboard`,
        config: {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            }
        },
        payload: {
            organizationId
        }
    });
}