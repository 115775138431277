import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListButton from '../../components/dashboard/ListButton';
import DocumentList from '../../components/dashboard/DocumentList';
import { Segment, Header, Form } from 'semantic-ui-react';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import ErrorMessage from '../../components/ErrorMessage';

class AsyncLists extends Component {
    constructor(props) {
        super(props);

        this.state = {
            list: props.lists[0],
            selectedIndex: 0,
            data: null,
            loading: false,
            error: '',
            page: 1,
            pageSize: 15,
            filterFromTo: ''
        };

        this.onCountUpdated = this.onCountUpdated.bind(this);
        this.onClick = this.onClick.bind(this);
        this.getData = this.getData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onFilterDatesChange = this.onFilterDatesChange.bind(this);
    }

    onCountUpdated(count) {
        const { list } = this.state;
        this.setState({ list: { ...list, count } });
    }

    onClick(e, { list, index }) {
        this.setState({ list: list, selectedIndex: index, error: '' });
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedIndex, data, loading, error } = this.state;
        const prevSelectedIndex = prevState.selectedIndex;

        if (!loading && (prevSelectedIndex !== selectedIndex || data == null) && error === '') {
            this.getData();
        }
    }

    async getData() {
        const { list, page, pageSize, filterFromTo } = this.state;

        const dates = filterFromTo.split('-');
        let startDate = null;
        let endDate = null;
        if (dates.length > 1) {
            startDate = dates[0].replace('/', '-');
            endDate = dates[1].replace('/', '-');
        }

        try {
            this.setState({ loading: true });
            const result = await list.dataAction(page, pageSize, startDate, endDate);

            this.setState({ data: result.response.documents, loading: false, error: '', list: { ...list, count: result.response.totalCount } });
        } catch ({ message }) {
            this.setState({ loading: false, error: message });
        }
    }

    onPageChange(e, { activePage }) {
        this.setState({ page: activePage });
    }

    onFilterDatesChange(e, { value }) {
        this.setState({ filterFromTo: value }, () => {
            const dates = value.split('-');
            if (dates.length > 1) {
                this.getData();
            }
        }); 
    }

    render() {
        const { list, data, loading, selectedIndex, pageSize, page, filterFromTo, error } = this.state;
        const { lists, onDocumentSelect } = this.props;
        const that = this;

        return (
            <div className='arraylists'>
                <div className='buttons'>
                    {lists.map((list, i) => {
                        return <ListButton key={i} list={list} onClick={that.onClick} index={i} active={i === selectedIndex} />;
                    })}
                </div>

                {list &&
                    <div>
                        <Segment className='filters'>
                            <Header as='h3'>Filters</Header>
                            <Form>
                                <Form.Group>
                                    <DatesRangeInput
                                        inlineLabel
                                        placeholder='From - To'
                                        label='Date'
                                        initialDate={moment()}
                                        value={filterFromTo}
                                        closable={true}
                                        closeOnMouseLeave={false}
                                        dateFormat='l'
                                        onChange={this.onFilterDatesChange} />


                                </Form.Group>
                            </Form>
                        </Segment>
                        <ErrorMessage error={error} />
                        <DocumentList fields={list.fields} data={data} loading={loading} totalCount={list.count} pageSize={pageSize} page={page} onPageChange={that.onPageChange} onDocumentSelect={onDocumentSelect} />
                    </div>
                }
            </div>
        );
    }
}

AsyncLists.propTypes = {
    lists: PropTypes.array.isRequired,
    onDocumentSelect: PropTypes.func.isRequired
};

export default AsyncLists;