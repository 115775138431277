import { createSelector } from 'reselect';
import { jwtDecode } from '../helpers/jwt';
import { sinceEpoch } from '../helpers/date';
import moment from 'moment';

const stateSessionSelector = (state) => state.session;

const jwtSelector = createSelector(
    [stateSessionSelector],
    (session) => {
        if (!session.token) return {};
        return jwtDecode(session.token);
    }
);

const sessionPolicySelector = createSelector(
    [jwtSelector],
    (jwt) => {
        if (jwt.payload && jwt.payload.pol){
            if (!Array.isArray(jwt.payload.pol)) return [jwt.payload.pol];
            return jwt.payload.pol;
        }
        return [];
    }
);

const sessionUserSelector = createSelector(
    [jwtSelector],
    (jwt) => {
        let user = {};
        if (jwt.payload && jwt.payload.given_name) user.firstName = jwt.payload.given_name;
        if (jwt.payload && jwt.payload.family_name) user.lastName = jwt.payload.family_name;
        if (jwt.payload && jwt.payload.full_name) user.name = jwt.payload.full_name;
        if (jwt.payload && jwt.payload.locale) user.locale = jwt.payload.locale;
        if (jwt.payload && jwt.payload.email_address) user.email = jwt.payload.email_address;
        return user;
    }
);

export const sessionSelector = createSelector(
    [stateSessionSelector, jwtSelector, sessionPolicySelector, sessionUserSelector],
    (session, jwt, policies, user) => {
        const authenticated = !!session.token && !!session.organization;
        const tokenExpires = moment(jwt.payload ? sinceEpoch(jwt.payload.exp) : new Date());
        const refreshExpires = moment(session.refreshExpires ? session.refreshExpires : new Date());
        const hasPolicy = search => !!policies.find(policy => policy.toLowerCase() === search.toLowerCase());
        return {...session, tokenExpires, refreshExpires, policies, hasPolicy, authenticated, user};
    }
);