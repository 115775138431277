import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import app from './appReducer';
import session from './sessionReducer';

const rootReducer = combineReducers({
    app,
    routing,
    session
});

export default rootReducer;