import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppLogo from './AppLogo';

const AppHeader = ({ username, organizationName, multipleOrgs, showMenu, showLogo, onMenuItemClick }) => {
    return (
        <div className='app-header wide'>
            <div className='inner'>
                <div className='menu-icon-container'>
                    {showMenu &&
                        <Icon name='bars' size='large' link onClick={onMenuItemClick} />
                    }
                </div>

                {showLogo &&
                    <AppLogo color wide />
                }

                <div className='user-container'>
                    <div className='user'>
                        <Dropdown trigger={<Icon name='user' link />} direction='left' floating>
                            <Dropdown.Menu>
                                <Dropdown.Header>{username}</Dropdown.Header>
                                <Dropdown.Header>{organizationName}</Dropdown.Header>
                                <Dropdown.Divider />

                                {multipleOrgs &&
                                    <Dropdown.Item><Link to='/switch'>Switch</Link></Dropdown.Item>
                                }

                                <Dropdown.Item><Link to='/logout'>Logout</Link></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
    );
};

AppHeader.propTypes = {
    username: PropTypes.string.isRequired,
    organizationName: PropTypes.string.isRequired,
    multipleOrgs: PropTypes.bool.isRequired,
    showLogo: PropTypes.bool.isRequired,
    showMenu: PropTypes.bool.isRequired,
    onMenuItemClick: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        multipleOrgs: state.session.organizations.length > 1
    };
};

export default withRouter(connect(mapStateToProps)(AppHeader));