import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { Menu, Segment } from 'semantic-ui-react';
import DocumentInformation from '../../components/document/DocumentInformation';
import Download from 'downloadjs';
import { Link } from "react-router-dom";

//import DataFormModal from '../DataFormModal';

class PDF extends Component {
    const

    constructor(props) {
        super(props);

       

        this.state = {
            numPages: 0,
            currentPage: 1
        };

        this.onDocumentLoad = this.onDocumentLoad.bind(this);
        this.onPageNavigate = this.onPageNavigate.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.getData = this.getData.bind(this);
    }

   

    onDocumentLoad({ numPages }) {
        this.setState({ numPages });
    }

    onPageNavigate(direction) {
        const { currentPage, numPages } = this.state;

        let newCurrentPage = currentPage;
        switch (direction) {
            case -2:
                newCurrentPage = 1;
                break;
            case 2:
                newCurrentPage = numPages;
                break;
            default:
                newCurrentPage = currentPage + direction;
                break;
        }

        this.setState({ currentPage: newCurrentPage });
    }

    onDownloadClick() {
        const { document } = this.props;
        const { filename } = document;

        Download(this.getData(), filename, 'application/pdf');
    }

    getData() {
        const { document } = this.props;
        const { base64 } = document;

        return `data:application/pdf;base64,${base64}`;
    }

    render() {
        const { currentPage, numPages } = this.state;
        const { document, showEdit, onEditClick } = this.props;
        const { filename } = document;

        return (
            <div className='document-container'>
                <Segment className='toolbar'>
                    <div className='filename'> 

                        <div className='text-container'><div className='text-inner'>                          
                            <div style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                <Link to="/">
                                    <button className='BacktoHistory' onClick={this.goBack}></button>
                                    {' '}
                                </Link>
                                {filename}{filename}</div></div></div>
                    </div>
                </Segment>
                {showEdit &&
                    <DocumentInformation document={document} />
                }
                <Menu className='toolbar'>
                    {numPages > 1 &&
                        <Menu.Menu>
                            <Menu.Item disabled={currentPage === 1} icon='fast backward' link onClick={() => this.onPageNavigate(-2)} />
                            <Menu.Item disabled={currentPage === 1} icon='backward' link onClick={() => this.onPageNavigate(-1)} />
                            <Menu.Item><div className='text-container'><div className='text-inner'>Page {currentPage} of {numPages}</div></div></Menu.Item>
                            <Menu.Item disabled={currentPage >= numPages} icon='forward' link onClick={() => this.onPageNavigate(1)} />
                            <Menu.Item disabled={currentPage >= numPages} icon='fast forward' link onClick={() => this.onPageNavigate(2)}></Menu.Item>
                        </Menu.Menu>
                    }
                    <Menu icon text size='huge' style={{paddingRight: '5px'}}>
                        {/* <DataFormModal
                            trigger={<Menu.Item icon='mail' link position='right' />}
                            type='email'
                            onSubmit={onSendEmail}
                            action='send'
                            icon='mail'
                            fields={[
                                { label: 'Email Address', name: 'emailAddress', isRequired: true, type: 'text' }
                            ]} /> */}

                        <Menu.Item icon='download' link onClick={this.onDownloadClick} position='right'/>
                        {showEdit &&
                            <Menu.Item icon='edit' link onClick={onEditClick} />
                        }
                    </Menu>
                </Menu>
                <Document file={this.getData()} loading='' onLoadSuccess={this.onDocumentLoad}>
                    <Page pageNumber={currentPage} renderMode='svg' />
                </Document>
            </div >
        );
    }
}

PDF.propTypes = {
    document: PropTypes.object.isRequired,
    showEdit: PropTypes.bool.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onSendEmail: PropTypes.func.isRequired
};

export default PDF;
