import React from 'react';

const AppFooter = props => {
    return (
        <div className='footer'>
            <div></div>
        </div>
    );
};

AppFooter.propTypes = {
    
};

export default AppFooter;