import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { routerActions } from 'connected-react-router';
import { withRouter } from 'react-router-dom';

export default function (ComposedComponent) {
    class Authenticate extends React.Component {
        constructor(props) {
            super(props);

            this._checkAndRedirect = this._checkAndRedirect.bind(this);
        }
        componentDidMount() {
            this._checkAndRedirect();
        }

        componentDidUpdate() {
            this._checkAndRedirect();
        }

        _checkAndRedirect() {
            const { actions, isAuthenticated } = this.props;

            if (!isAuthenticated) {
                actions.push('/login')
            }
        }

        render() {
            return (
                <div>
                    {this.props.isAuthenticated ? <ComposedComponent {...this.props} /> : null}
                </div>
            );
        }
    }

    const mapStateToProps = (state) => {
        return {
            isAuthenticated: (state.session.token && state.session.organization)
        };
    };

    const mapDispatchToProps = (dispatch) => ({
        actions: {
            ...bindActionCreators(routerActions, dispatch)
        }
    });
    
    return withRouter(connect(
        mapStateToProps,
        mapDispatchToProps
    )(Authenticate));
}