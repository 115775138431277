import { sessionSelector } from '../selectors/sessionSelectors';
import moment from 'moment';

class ApiError extends Error {
    constructor(message, data) {
        super(message);
        this.error = data;
        if (Error.captureStackTrace) Error.captureStackTrace(this, ApiError);
    }
}

export function api({ types, uri, config = {}, shouldCallAPI = () => true, sendJwtBearer = true, payload = {} }) {
    return (dispatch, getState) => {

        if (!Array.isArray(types) || types.length !== 3 || !types.every(type => typeof type === 'string'))
            throw new Error('Expected an array of three string types.');

        if (typeof uri !== 'string')
            throw new Error('Expected uri to be a string.');

        if (!shouldCallAPI(getState())) return;

        if (sendJwtBearer) {
            return ensureJwt(getState(), dispatch)
                .then((token) => {
                    return dispatch(execute({ uri, config, types, payload, sendJwtBearer }));
                });
        }

        return dispatch(execute({ uri, config, types, payload, sendJwtBearer }));
    };
}

function execute({ uri, config, types, payload, sendJwtBearer }) {
    return (dispatch, getState) => {
        const [requestType, successType, failureType] = types;
        const tokenState = getState();
        let requestConfig = config;
        if (sendJwtBearer && tokenState.session && tokenState.session.token) {
            const authHeader = { 'AUTHORIZATION': `Bearer ${getState().session.token}` };
            requestConfig = { ...config, headers: { ...config.headers, ...authHeader } };
        }

        const { headers } = requestConfig;

        const noCacheConfig = { ...requestConfig, cache: 'no-cache', headers: { ...headers, ...{ 'pragma': 'no-cache', 'cache-control': 'no-cache' } } };

        dispatch({ ...payload, type: requestType });

        return fetch(uri, noCacheConfig)
            .then(response => response.json(), error => {
                dispatch({ payload, error, type: failureType });
                throw error;
            })
            .then(response => {
                if (response.success === undefined) return dispatch({ ...payload, response, type: successType });
                if (response.success) return dispatch({ payload, response: response.result, type: successType });
                dispatch({ payload, error: response.errors, type: failureType });
                throw new ApiError(response.errors.length > 0 ? response.errors[0] : 'Api returned an error response', response.errors);
            }, error => {
                dispatch({ payload, error, type: failureType });
                throw error;
            });
    };
}

function ensureJwt(state, dispatch) {
    return new Promise((resolve) => {
        const tokenState = sessionSelector(state);
        if (!tokenState.token)
            throw new Error('User must be logged in');

        const serverNow = moment().add('milliseconds', tokenState.server_offset);
        if (moment(tokenState.tokenExpires) < serverNow)
            throw new Error('User must be logged in');

        resolve(tokenState.token);
    });
}
