export const DocumentStatus = {
    Rejected: -1,
    AwaitingImport: 0,
    AwaitingApproval: 1,
    ApprovalFailed: 2,
    Approved: 3,
    Exported: 4
};

export const DocumentStatusText = {
    [DocumentStatus.Rejected]: 'Rejected',
    [DocumentStatus.AwaitingImport]: 'Awaiting Import',
    [DocumentStatus.AwaitingApproval]: 'Awaiting Approval',
    [DocumentStatus.ApprovalFailed]: 'Approval Failed',
    [DocumentStatus.Approved]: 'Approved',
    [DocumentStatus.Exported]: 'Exported'
};

export const Reason = {
    IncorrectInformation: 0,
    InsufficientInformation: 1,
    InvalidInformation: 2
};

export const ReasonText = {
    [Reason.IncorrectInformation]: 'Incorrect Information',
    [Reason.InsufficientInformation]: 'Insufficient Information',
    [Reason.InvalidInformation]: 'Invalid Information'
};