import React from 'react';
import PropTypes from 'prop-types';
import { Table, Dimmer, Loader, Pagination, Icon } from 'semantic-ui-react';
import { NullableDateTime } from '../../common/formatting';
import EmptyTableRow from '../EmptyTableRow';

const DocumentList = ({ fields, loading, data, totalCount, page, pageSize, onPageChange, onDocumentSelect }) => {
    const getCell = (type, fieldData) => {
        switch (type) {
            case 'string':
                return fieldData;
            case 'datetime':
                return NullableDateTime(fieldData);
            default:
                return null;
        }
    };

    const totalPages = Math.floor(totalCount / pageSize) + 1;
    const needsPaging = totalPages > 1;
    
    return (
        <div className='documentlist'>
            {!!loading &&
                <Dimmer active>
                    <Loader />
                </Dimmer>
            }
            <Table className='documents'>
                <Table.Header>
                    <Table.Row>
                        {fields.map((field, i) => {
                            return (<Table.HeaderCell key={i}>{field.name}</Table.HeaderCell>);
                        })}
                        <Table.HeaderCell>Actions</Table.HeaderCell>                        
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data &&
                        data.map((item, i) => {
                            return (<Table.Row key={i}>
                                {fields.map((field, c) => {
                                    return (<Table.Cell key={c}>{getCell(field.type, item[field.data])}</Table.Cell>);
                                })}
                                <Table.Cell>
                                    <Icon link name='file' onClick={() => onDocumentSelect(item['id'])} />
                                </Table.Cell>
                            </Table.Row>);
                        })
                    }

                    {(!data || data.length === 0) &&
                        <EmptyTableRow tableName='Documents' colSpan={fields.length + 1} />
                    }
                </Table.Body>
            </Table>
            {needsPaging &&
                <Pagination activePage={page} onPageChange={onPageChange} totalPages={totalPages}  />
            }
        </div>
    );
};

DocumentList.propTypes = {
    fields: PropTypes.array.isRequired,
    data: PropTypes.array,
    loading: PropTypes.bool,
    totalCount: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired
};

export default DocumentList;