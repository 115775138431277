import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';
import AppLogo from './AppLogo';

const AppSidebar = ({ onItemClick }) => {
    return (
        <div className='sidebar wide'>
            <AppLogo wide />
            <Menu secondary vertical>
                <Menu.Item header exact as={NavLink} to='/' name='dashboard' onClick={onItemClick}>
                    <Icon name='dashboard' />
                    Dashboard
                </Menu.Item>
                <Menu.Item header exact as={NavLink} to='/configuration' name='configuration' onClick={onItemClick}>
                    <Icon name='configure' />
                    Configuration
                </Menu.Item>
            </Menu>
        </div>
    );
};

AppSidebar.propTypes = {
    onItemClick: PropTypes.func.isRequired
};

export default AppSidebar;