import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItems from './ListItems'
import withAuth from '../../withAuth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { routerActions } from 'react-router-redux';
import * as configurationActions from '../../actions/configurationActions';

class Departments extends Component {
    render() {
        const { departments, actions, onUpdate, organizationId } = this.props;

        return (
            <div>
                <ListItems type='department'
                    items={departments}
                    icon='sitemap'
                    addAction={actions.addDepartment}
                    removeAction={actions.removeDepartment}
                    onUpdate={onUpdate}
                    organizationId={organizationId} />
            </div>
        );
    }
}

Departments.propTypes = {
    departments: PropTypes.array.isRequired,
    onUpdate: PropTypes.func,
    organizationId: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
    return {
        organizationId: state.session.organization.id
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: {
        ...bindActionCreators(routerActions, dispatch),
        ...bindActionCreators(configurationActions, dispatch)
    }
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Departments));