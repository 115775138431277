import React from 'react';
import PropTypes from 'prop-types';
import { Button, Label } from 'semantic-ui-react';

const ListButton = ({ list, onClick, index, active }) => {
    const { text, count, color } = list;

    return (
        <Button className='button' as='div' labelPosition='right' onClick={onClick} list={list} index={index}>
            <Button color={!!active ? color : 'grey'}>
                {text}
            </Button>
            <Label as='a' basic color={!!active ? color : 'grey'} pointing='left'>
                {count}
            </Label>
        </Button>
    );
};

ListButton.propTypes = {
    list: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool
};

export default ListButton;