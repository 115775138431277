import * as types from './actionTypes';
import { api } from './api';

export function authenticate(username, password) {
    return api({
        types: [types.USER__AUTHENTICATE_REQUEST, types.USER__AUTHENTICATE_SUCCESS, types.USER__AUTHENTICATE_FAILURE],
        uri: '/api/user/authenticate',
        sendJwtBearer: false,
        config: {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username,
                password
            })
        },
        payload: {
            username
        }
    });
}

export function getOrganizations() {
    return api({
        types: [types.USER__GET_ORGANIZATIONS_REQUEST, types.USER__GET_ORGANIZATIONS_SUCCESS, types.USER__GET_ORGANIZATIONS_FAILURE],
        uri: '/api/user/organizations',
        config: {
            method: 'get',
            headers: {
               'Accept': 'application/json'
            }
        }
    });
}

export function login(organization) {
    return api({
        types: [types.USER__LOGIN_REQUEST, types.USER__LOGIN_SUCCESS, types.USER__LOGIN_FAILURE],
        uri: '/api/user/login',
        config: {
            method: 'post',
            headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json'
            },
            body: JSON.stringify( organization )
        },
        payload: { organization }
    });
}

export function logout() {
    return { type: types.USER__LOGOUT };
}

export function refreshToken(token, refresh){
    const body = {
        token,
        refresh
    };

    return {
        types: [types.SESSION__REFRESH_TOKEN_REQUEST, types.SESSION__REFRESH_TOKEN_SUCCESS, types.SESSION__REFRESH_TOKEN_FAILURE],
        uri: '/api/user/refresh',
        config: {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        },
        payload: body
    };
}