import React from 'react';
import Numeral from 'numeral';
import Moment from 'moment';
import { Loader } from 'semantic-ui-react';

export function NullableCurrency(value, loading) {
    if (loading) return <div style={{ textAlign: 'center' }}><Loader active inline size='tiny' /></div>;

    Numeral.nullFormat('---');
    return Numeral(value).format('$0,0.00');
}

export function NullableDate(value, loading) { // eslint-disable-line react/no-multi-comp
    if (loading) return <div style={{ textAlign: 'center' }}><Loader active inline size='tiny' /></div>;

    if (!value || (value.isValid && !value.isValid())) return '---';
    return Moment(value).format('LL');
}

export function NullableDateTime(value, loading) { // eslint-disable-line react/no-multi-comp
    if (loading) return <div style={{ textAlign: 'center' }}><Loader active inline size='tiny' /></div>;

    if (!value || (value.isValid && !value.isValid())) return '---';
    return DateTime(value);
}

export function DateTime(value) {
    return Moment(value).format('LLL');
}

export function DateTimeRelative(value) {
    return Moment(value).fromNow();
}