import withAuth from '../withAuth'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Header, Dimmer, Loader } from 'semantic-ui-react';
import AsyncLists from './dashboard/AsyncLists';
import * as dashboardActions from '../actions/dashboardActions';
import { bindActionCreators } from 'redux';
import { routerActions } from 'react-router-redux';
import PropTypes from 'prop-types';

class DashboardPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dashboard: null
        };

        this.update = this.update.bind(this);
        this.getLists = this.getLists.bind(this);
        this.onDocumentSelect = this.onDocumentSelect.bind(this);
    }

    async componentDidMount() {
        await this.update();
    }

    async update() {
        const { organizationId, actions } = this.props;

        if (organizationId) {
            this.setState({ loading: true });

            try {
                const result = await actions.getDashboard(organizationId);
                this.setState({ loading: false, dashboard: result.response, error: '' });
            } catch (message) {
                this.setState({ loading: false, dashboard: null, error: message });
            }
        }
    }

    onDocumentSelect(documentId) {
        window.open(`/document/${documentId}`, '_self', false);
    }

    getLists() {
        const { dashboard } = this.state;
        const { actions, organizationId } = this.props;

        return dashboard ? [{
            text: 'New Documents',
            color: 'blue',
            count: dashboard.newDocumentsCount,
            dataAction: (page, pageSize, startDate, endDate) => actions.getNewDocuments(organizationId, page, pageSize, startDate, endDate),
            fields: [{
                name: 'Filename',
                data: 'filename',
                type: 'string',
                url: 'url'
            }, {
                name: 'Intake Date/Time',
                data: 'intakeDateTime',
                type: 'datetime'
            }]
        }, {
            text: 'My Approvals',
            color: 'pink',
            count: dashboard.myApprovalsCount,
            dataAction: (page, pageSize, startDate, endDate) => actions.getMyApprovalDocuments(organizationId, page, pageSize, startDate, endDate),
            fields: [{
                name: 'Filename',
                data: 'filename',
                type: 'string',
                url: 'url'
            }, {
                name: 'Intake Date/Time',
                data: 'intakeDateTime',
                type: 'datetime'
            }, {
                name: 'Import Date/Time',
                data: 'importedDateTime',
                type: 'datetime'
            }]
        }, {
            text: 'Sent Back',
            color: 'orange',
            count: dashboard.sentBackCount,
            dataAction: (page, pageSize, startDate, endDate) => actions.getSentBackDocuments(organizationId, page, pageSize, startDate, endDate),
            fields: [{
                name: 'Filename',
                data: 'filename',
                type: 'string',
                url: 'url'
            }, {
                name: 'Intake Date/Time',
                data: 'intakeDateTime',
                type: 'datetime'
            }, {
                name: 'Failed Date/Time',
                data: 'lastSentBackDateTime',
                type: 'datetime'
            }]
        }, {
            text: 'Rejected',
            color: 'red',
            count: dashboard.rejectedCount,
            dataAction: (page, pageSize, startDate, endDate) => actions.getRejectedDocuments(organizationId, page, pageSize, startDate, endDate),
            fields: [{
                name: 'Filename',
                data: 'filename',
                type: 'string',
                url: 'url'
            }, {
                name: 'Intake Date/Time',
                data: 'intakeDateTime',
                type: 'datetime'
            }, {
                name: 'Rejected Date/Time',
                data: 'rejectedDateTime',
                type: 'datetime'
            }]
        }, {
            text: 'Approved',
            color: 'brown',
            count: dashboard.approvedCount,
            dataAction: (page, pageSize, startDate, endDate) => actions.getApprovedDocuments(organizationId, page, pageSize, startDate, endDate),
            fields: [{
                name: 'Filename',
                data: 'filename',
                type: 'string',
                url: 'url'
            }, {
                name: 'Intake Date/Time',
                data: 'intakeDateTime',
                type: 'datetime'
            }, {
                name: 'Primary Approval Date/Time',
                data: 'primaryApprovedDateTime',
                type: 'datetime'
            }, {
                name: 'Secondary Approval Date/Time',
                data: 'secondaryApprovedDateTime',
                type: 'datetime'
            }]
        }] : [];
    }

    render() {
        const { loading } = this.state;
        const lists = this.getLists();
        return (
            <div>
                {loading &&
                    <Dimmer active>
                        <Loader content='Loading' />
                    </Dimmer>
                }
                <Segment raised>
                    <Header as='h2'>Documents</Header>
                    {lists.length > 0 &&
                        <AsyncLists lists={lists} onDocumentSelect={this.onDocumentSelect} />
                    }
                </Segment>
            </div>
        );
    }
}

DashboardPage.propTypes = {
    organizationId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
    return {
        organizationId: state.session.organization.id
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: {
        ...bindActionCreators(routerActions, dispatch),
        ...bindActionCreators(dashboardActions, dispatch)
    }
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(DashboardPage));