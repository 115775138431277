export function generateOptions(enumeration, enumerationText) {
    var options = [];
    for (var key in enumeration) {
        // check also if property is not inherited from prototype
        if (enumeration.hasOwnProperty(key)) {
            var value = enumeration[key];
            var text = enumerationText[value];
            
            options.push({
                key: value,
                value,
                text
            });
        }
    }

    return options;
}