import React, { Component } from 'react';
import { Header, Segment, Dimmer, Loader } from 'semantic-ui-react';
import withAuth from '../withAuth';
import { connect } from 'react-redux';
import Vendors from './configuration/Vendors';
import Departments from './configuration/Departments';
import ExpenseAllocationAccounts from './configuration/ExpenseAllocationAccounts';
import ApprovalWorkflows from './configuration/ApprovalWorkflows';
import { bindActionCreators } from 'redux';
import { routerActions } from 'react-router-redux';
import PropTypes from 'prop-types';
import * as configurationActions from '../actions/configurationActions';
import ErrorMessage from '../components/ErrorMessage';

class ConfigurationPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            configuration: null,
            loading: false,
            error: ''
        };

        this.onUpdate = this.onUpdate.bind(this);
    }

    async componentDidMount() {
        await this.onUpdate();
    }

    async onUpdate() {
        const { organizationId, actions } = this.props;

        if (organizationId) {
            this.setState({ loading: true });

            try {
                const result = await actions.getConfiguration(organizationId);
                this.setState({ loading: false, configuration: result.response, error: '' });
            } catch ({ message }) {
                this.setState({ loading: false, configuration: null, error: message });
            }
        }
    }

    render() {
        const { configuration, loading, error } = this.state;
        const { departments, vendors, approvalWorkflows, expenseAllocationAccounts, users } = (configuration || {});

        return (
            <div className='configuration'>
                {loading &&
                    <Dimmer active>
                        <Loader content='Loading' />
                    </Dimmer>
                }
                <ErrorMessage error={error} />
                <Segment raised className='split'>
                    <Header as='h2'>Entities</Header>
                    <div className='content'>
                        <div className='part'>
                            <Departments departments={departments || []} onUpdate={this.onUpdate} />
                        </div>
                        <div className='part'>
                            <Vendors vendors={vendors || []} onUpdate={this.onUpdate} />
                        </div>
                    </div>
                </Segment>
                <Segment raised className='split'>
                    <ApprovalWorkflows approvalWorkflows={approvalWorkflows || []} departments={departments || []} users={users || []} onUpdate={this.onUpdate} />
                </Segment>
                <Segment raised className='split'>
                    <ExpenseAllocationAccounts expenseAllocationAccounts={expenseAllocationAccounts || []} onUpdate={this.onUpdate} />
                </Segment>
            </div>
        );
    }
};

ConfigurationPage.propTypes = {
    organizationId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
    return {
        organizationId: state.session.organization.id
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: {
        ...bindActionCreators(routerActions, dispatch),
        ...bindActionCreators(configurationActions, dispatch)
    }
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ConfigurationPage));